import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  LinearProgress,
  Typography,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Link
} from '@material-ui/core';
import { CategoryToolbar } from './components';
import { Portlet, PortletContent } from 'components';
import styles from './styles';

//graphql
import { useQuery } from 'react-apollo-hooks';
import { listCategorys } from '../../graphql/queries';
import gql from 'graphql-tag';

const getCategorys = gql`${listCategorys}`;

const GetCategorysQuery = gql`
  ${getCategorys}
`;

const CategoryList = props => {
  const { classes } = props;
  const [limit] = useState(10000);
  const [categorys, setCategorys] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search,setSearch] = useState('')

  const CategoryLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

  const { data, loading, refetch } = useQuery(GetCategorysQuery, {
    variables: { limit: limit },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (
      data.listCategorys !== null &&
      typeof data.listCategorys !== 'undefined'
    ) {
      setCategorys(data.listCategorys.items);
      //setLimit((data.listHdtvCategorys.items.length > limit) ? limit:data.listHdtvCategorys.items.length)
    }
  }, [data]);

  useEffect(() => {
    setPage(window.localStorage.getItem('categoryListPage') === null ? 0:window.localStorage.getItem('categoryListPage'))
  },[])

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, categorys.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    window.localStorage.setItem('categoryListPage',newPage)
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onSearch = (event) => {
    setPage(0)
    setSearch(event.target.value)
  }

  function filterCategorys(q, item) {
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    return ( searchRegex.test(item.id) || searchRegex.test(item.name) || searchRegex.test(item.description) || searchRegex.test(moment(item.createdAt.slice(0, 16) + '+0000')
    .tz('America/Chicago')
    .format('llll')));
  }

  return (
      <div className={classes.root}>
        <CategoryToolbar refetch={refetch} search={onSearch}/>
        <div className={classes.content}>
          {loading && (
            <div className={classes.progressWrapper}>
              <LinearProgress />
            </div>
          )}
          {categorys.length === 0 && (
            <Typography variant="h6">There are no categories available</Typography>
          )}
          {categorys.length > 0 && (
            <Portlet >
              <PortletContent noPadding>
                <PerfectScrollbar>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">GUID</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Desc</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categorys
                      .filter(category => filterCategorys(search,category))
                      .sort(function compare(a, b) {
                        var dateA = new Date(a.createdAt.slice(0, 16));
                        var dateB = new Date(b.createdAt.slice(0, 16));
                        return dateB - dateA;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(category => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={category.id}>
                          <TableCell className={classes.tableCell}>
                            <Link component={CategoryLink} to={'/categories/'+category.id}>
                              {category.id}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {category.name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {category.description}
                          </TableCell>
                        </TableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 56 * emptyRows }}>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={4}
                        count={categorys.length}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        page={parseInt(page)}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        SelectProps={{
                          inputProps: { 'aria-label': 'Rows per page' },
                          native: true
                        }}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </PerfectScrollbar>
            </PortletContent>
          </Portlet>
          )}
        </div>
      </div>
  );
};

CategoryList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CategoryList);
