import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles, IconButton, Button, ButtonGroup, Link, FormControlLabel, Checkbox} from '@material-ui/core';
import { SearchInput } from 'components';
import styles from './styles';
import ProgressBar from '../../../../components/ProgressBar'
import { Refresh as RefreshIcon } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom';


const DesignerToolbar = (props) => {

  const { classes, className, search, statuses, filter, status: _status, proOnly, setProOnly} = props;
  const [ loaded ] = useState(null)

  const DesignerLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);


  const refresh = () => {
    props.refetch()
  };


  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {loaded &&
        <div className={classes.row}>
          <ProgressBar progress={loaded} />
        </div>
      }
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Link component={DesignerLink} to={'/designers/new'}>
          <Button color="primary" size="small" variant="outlined" >
            Create New Designer
          </Button>
        </Link>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search designer"
          onChange={search}
        />
        <span className={classes.spacer} />
        <FormControlLabel control={<Checkbox checked={proOnly} color={'primary'} onChange={() => setProOnly(!proOnly)}/>} label={'Pro only'} />
        <ButtonGroup>
          {statuses.map((status, index) => <Button key={index.toString()} disabled={_status=== status.toLowerCase()} onClick={filter(status.toLowerCase())}>{status}</Button>)}
        </ButtonGroup>
        <IconButton aria-label="Add" onClick={refresh}>
          <RefreshIcon color="primary" />
        </IconButton>
        {/* <DisplayMode mode="list" /> */}
      </div>
    </div>
  );
}

DesignerToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DesignerToolbar);
