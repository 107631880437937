import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, IconButton, Button } from '@material-ui/core';
import { SearchInput } from 'components';
import styles from './styles';
import { Refresh as RefreshIcon } from '@material-ui/icons'


const TagsToolbar = ({classes, refetch, onNewTag, onSearch, className}) => {
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search video"
          onChange={onSearch}
        />
        <span className={classes.spacer} />
        <IconButton aria-label="Add" onClick={() => refetch()}>
          <RefreshIcon color="primary" />
        </IconButton>
        <Button onClick={onNewTag}>
          Add
        </Button>
      </div>
    </div>
  );
};

TagsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TagsToolbar);
