import React, { useState, useEffect } from 'react';
import { withStyles, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {useQuery} from "react-apollo-hooks";
import gql from 'graphql-tag';

import {listProducts} from "../../graphql/queries";

import { SearchInput } from 'components';

import styles from './styles'

const ProductLibrary = (props) => {
  const [open, setOpen] = useState(false);
  const { onClickItem, classes } = props;
  const [ products, setProducts ] = useState([]);
  const [ search, setSearch ] = useState('');

  const {data} = useQuery(gql(listProducts), {variables: {limit: 10000}});

  useEffect(() => {
    if (data.listProducts) {
      setProducts(data.listProducts.items);
    }
  },[data]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }


  const onSearch = (event) => {
    setSearch(event.target.value)
  };

  function filterProducts(q, item) {
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    return ( searchRegex.test(item.id) || searchRegex.test(item.name) )
  }

  return (
    <div className={classes.root}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Product
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
            <SearchInput
            className={classes.searchInput}
            placeholder="Search products"
            onChange={onSearch}
            />
        </DialogTitle>
        <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Image</TableCell>
                        <TableCell>Product Name</TableCell>
                        <TableCell>UUID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.length > 0 && (
                        products.filter(product => filterProducts(search,product)).map((product) => {
                            return (<TableRow key={product.id} onClick={() => { onClickItem(product); handleClose() }} className={classes.TableRow}>
                                <TableCell><img src={product.image} height={100} alt={product.name}/></TableCell>
                                <TableCell>{product.name}</TableCell>
                                <TableCell>{product.id}</TableCell>
                            </TableRow>)
                        })
                    )}
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleClose} color="primary">
            Set
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withStyles(styles)(ProductLibrary);
