import {useQuery} from "react-apollo-hooks";
import gql from 'graphql-tag'

const query = gql(`query ListVideos(
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      tags {
        items {
          id
          tag {
          id
          }
        }
        nextToken
      }
    }
    nextToken
  }
}`);

export default function useVideos (fetchPolicy='cache-first', limit=1000) {
  return useQuery(query, {
    variables: {limit},
    fetchPolicy
  });
}
