/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Typography, Button, Avatar } from '@material-ui/core';
import { Portlet, PortletContent, PortletFooter } from 'components';
import styles from './styles';
import { Storage } from 'aws-amplify'
import ProgressBar from 'components/ProgressBar'
import uuid from 'uuid'

const AccountProfile = (props) => {
  const { classes, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const { user } = props

  const [ selectedFile, setSelectedFile ] = useState(null)
  const [ loaded, setLoaded ] = useState(null)
  const [ disabled, setDisabled ] = useState(false)

  const onChangeHandler=event=>{
    setSelectedFile(event.target.files[0])
  }

  const uploadFile = () => {
    setDisabled(true)
    let ext = selectedFile.name.split('.').pop();
    let key = uuid()+'.'+ext;
    Storage.put('images/'+key, selectedFile, {
      bucket: 'hdtv-prod',
      level: 'public',
      progressCallback(progress) {
        setLoaded(progress)
      },
    }).then((res) => {
      setDisabled(false)
      setLoaded(null)

    });
  
  }

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletContent>
        <div className={classes.details}>
          <div className={classes.info}>
            <Typography variant="h2">{user.email}</Typography>
            <Typography className={classes.locationText} variant="body1">
              Test
            </Typography>
            <Typography className={classes.dateText} variant="body1">
              Today
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            src={user.photo_url}
          />
        </div>
        <div className={classes.progressWrapper}>
          <Typography variant="body1">Public Profile</Typography>
          {loaded && 
            <div className={classes.row}>
              <ProgressBar progress={loaded} />
            </div>
          }
        </div>
      </PortletContent>
      <PortletFooter>
        <input color="primary" size="small" variant="outlined" type="file" name="file" onChange={onChangeHandler}/>
        <Button color="primary" size="small" variant="outlined" onClick={uploadFile} disabled={!selectedFile || disabled}>
          Upload
        </Button>
        <Button variant="text">Remove photo</Button>
      </PortletFooter>
    </Portlet>
  );
}

AccountProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default withStyles(styles)(AccountProfile);
