import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, IconButton, Button, Link } from '@material-ui/core';
import { SearchInput } from 'components';
import styles from './styles';
import ProgressBar from '../../../../components/ProgressBar'
import { Refresh as RefreshIcon } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom';


const CategoryToolbar = (props) => {

  const { classes, className, search } = props
  const [ loaded ] = useState(null)

  const CategoryLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);


  const refresh = () => {
    props.refetch()
  }
  

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {loaded && 
        <div className={classes.row}>
          <ProgressBar progress={loaded} />
        </div>
      }
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Link component={CategoryLink} to={'/categories/new'}>
          <Button color="primary" size="small" variant="outlined" >
            Create New Category
          </Button>
        </Link>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search category"
          onChange={search}
        />
        <span className={classes.spacer} />
        <IconButton aria-label="Add" onClick={refresh}>
          <RefreshIcon color="primary" />
        </IconButton>
        {/* <DisplayMode mode="list" /> */}
      </div>
    </div>
  );
}

CategoryToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CategoryToolbar);
