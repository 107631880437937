import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Typography, Divider, TextField } from '@material-ui/core';
import {
  AccessTime as AccessTimeIcon,
  GetApp as GetAppIcon
} from '@material-ui/icons';
import { Paper } from 'components';
import styles from './styles';
import ReactPlayer from 'react-player'

import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { getPost } from '../../../../graphql/queries';
import { createPost, updatePost } from '../../../../graphql/mutations';

const GetPostQuery = gql`${getPost}`
const CreatePostQuery = gql`${createPost}`
const UpdatePostQuery = gql`${updatePost}`

const PostCard = (props) => {

  const { classes, className, post } = props
  const [ controls ] = useState(true)
  const [ publicPost, setPublicPost ] = useState([])
  const { data, loading } = useQuery(GetPostQuery, {
    variables: { id: post.guid },
    fetchPolicy: 'cache-first'
  })

  const rootClassName = classNames(classes.root, className)
  const url = post.hlsUrl

  const createPost = useMutation(CreatePostQuery); 
  const updatePost = useMutation(UpdatePostQuery); 

  const fileConfig = {
    file: { 
      attributes: { 
        autoPlay: false,
        preload: 'none'
      }
      // hlsOptions: {
      //   autoStartLoad: false
      // } 
    } 
  }

  const allowedFields = ['id', 'title', 'description', 'url', 'thumbnail'];

  const onChange = (event, name) => {
    if(allowedFields.includes(name)){
      setPublicPost({ ...publicPost, [name]: event.target.value });
    }
  };

  const onBlur = () => {
    const input = Object.keys(publicPost).reduce((object, key) => {
      if (allowedFields.includes(key) && publicPost[key] !== null) {
        object[key] = publicPost[key];
      }
      return object;
    }, {});
    const payload = {
      variables: {
        input: input
      }
    };
    updatePost(payload);
  }

  useEffect(() => {
    let thumb = post.thumbNailUrl ? post.thumbNailUrl[0].slice(0,-11)+'0000025.jpg':null
    if(data.getPost !== null && typeof data.getPost !== 'undefined') setPublicPost({...data.getPost,'thumbnail':thumb,'url':post.hlsUrl ? post.hlsUrl:null})
  },[data, post.hlsUrl, post.thumbNailUrl])

  useEffect(() => {
    console.log('useffect..')
    if(!loading && data.getPost === null){
      let thumb = post.thumbNailUrl ? post.thumbNailUrl[0].slice(11)+'0000025.jpg':null
      createPost({ variables: { input: { id: post.guid, url: post.hlsUrl, thumbnail: thumb } } }).then(data=>setPublicPost(data.data.createPost)).catch(err=>console.log(err))
    }
  },[createPost, data, loading, post.guid, post.hlsUrl, post.thumbNailUrl])

  return (
    <Paper className={rootClassName}>
      {post.hlsUrl === null ? (
        <Typography className={classes.description} variant="body1">
          Post currently transcoding...
        </Typography>
      ):(
        <ReactPlayer url={url} controls={controls} height='' width='100%' config={fileConfig} light={publicPost.thumbnail}/>
      )}
      <div className={classes.details}>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="Title"
            margin="dense"
            onChange={event=>onChange(event,'title')}
            onBlur={event=>onBlur()}
            required
            value={publicPost.title ? publicPost.title:''}
            variant="outlined"
          />
        </div>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="Description"
            margin="dense"
            onChange={event=>onChange(event,'description')}
            onBlur={event=>onBlur()}
            required
            value={publicPost.description ? publicPost.description:''}
            variant="outlined"
          />
        </div>
      </div>
      <Divider />
      <div className={classes.stats}>
        <AccessTimeIcon className={classes.updateIcon} />
        <Typography className={classes.updateText} variant="body2">
          {new Date(post.startTime.slice(0,10)).toDateString()}
        </Typography>
        <GetAppIcon className={classes.downloadsIcon} />
        <Typography className={classes.downloadsText} variant="body2">
          {(JSON.parse(post.srcMediainfo).container.fileSize/1048576).toFixed(0)} MB
        </Typography>
      </div>
    </Paper>
  );
  
}

PostCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired
};

export default withStyles(styles)(PostCard);
