import React, { useState } from 'react'
import ReactPlayer from 'react-player'

export default function VideoPlayer (props) {
    const [ controls ] = useState(true)
    
    const fileConfig = {
        file: { 
          attributes: {
            preload: 'none'
          }
        } 
    }
    return (
        <ReactPlayer url={props.video.url} controls={controls} width='100%' config={fileConfig} light={props.video.thumbnail}/>
    )
}
