import React, { useEffect, useState } from "react";
import {
  Button,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from "@material-ui/core";
import TimeField from "react-simple-timefield";
import { API, graphqlOperation } from "aws-amplify";
import {
  deleteProductVideo,
  updateProductVideo
} from "../../../../../../graphql/mutations";

const VideoProducts = ({
  classes,
  refetch,
  products: _products
}) => {
  const [timestamp, setTimestamp] = useState(1);
  const [products, setProducts] = useState([]);

  const secondsToHMS = d => {
    console.log(d);
    d = Number(d);
    const h = `${Math.floor(d / 3600)}`;
    const m = `${Math.floor((d % 3600) / 60)}`;
    const s = `${Math.floor((d % 3600) % 60)}`;
    return `${h.length === 1 ? `0${h}` : h}:${m.length === 1 ? `0${m}` : m}:${
      s.length === 1 ? `0${s}` : s
    }`;
  };

  const hmsToSeconds = hms => {
    hms = hms.split(':');
    return Number(hms[0] * 3600) + Number(hms[1] * 60) + Number(hms[2]);
  };

  useEffect(() => {
    const prods = [..._products.items];
    for (let p of prods) {
      p.timestamp = secondsToHMS(p.timestamp);
    }
    setProducts(prods);
  }, [_products]);

  const onChangeTimestamp = (value, i) => {
    const newProducts = [...products];
    newProducts[i].timestamp = value;
    setProducts(newProducts);
    setTimestamp(value);
  };

  const handleUpdateProductVideo = async id => {
    let productVideo = await API.graphql(
      graphqlOperation(updateProductVideo, {
        input: { id: id, timestamp: hmsToSeconds(timestamp) }
      })
    );
    console.log(productVideo);
  };

  const deletePV = async id => {
    let result = await API.graphql(
      graphqlOperation(deleteProductVideo, { input: { id: id } })
    );
    console.log(result);
    refetch();
  };

  return (
    <TableBody>
      {products.length > 0 &&
        products.map((product, i) => {
          return (
            <TableRow key={product.id} className={classes.TableRow}>
              <TableCell>{product.product.id}</TableCell>
              <TableCell>{product.product.name}</TableCell>
              <TableCell>
                <TimeField
                  showSeconds
                  input={
                    <TextField
                      classes={{ root: classes.textField }}
                      name="timestamp"
                      onBlur={() => handleUpdateProductVideo(product.id)}
                      type="text"
                      variant="outlined"
                    />
                  }
                  value={product.timestamp ? product.timestamp : ""}
                  onChange={(_, value) => onChangeTimestamp(value, i)}
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => deletePV(product.id)}
                >
                  Remove
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default VideoProducts;
