import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Button, TextField } from '@material-ui/core';

//graphql
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { updateCategory } from 'graphql/mutations';
import { createCategory } from 'graphql/mutations';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';
import 'react-quill/dist/quill.snow.css'

const UpdateCategoryQuery = gql`${updateCategory}`
const CreateCategoryQuery = gql`${createCategory}`

const CategoryDetails = (props) => {
    const [ category, setCategory ] = useState({...props.category})
    const { classes, className } = props;
    const rootClassName = classNames(classes.root, className);
    const [ create, setCreate ] = useState(false)
    const [ disabled, setDisabled ] = useState(false)

    const updateCategory = useMutation(UpdateCategoryQuery);
    const createCategory = useMutation(CreateCategoryQuery);
    
    const allowedFields = ['id', 'name', 'description'];
    const handleSubmit = async () => {
      setDisabled(true)
      const input = Object.keys(category).reduce((object, key) => {
        if (allowedFields.includes(key) && category[key] !== null) {
          object[key] = category[key];
        }
        return object;
      }, {});
      const payload = {
        variables: {
          input: input
        }
      };
      await ((create) ? createCategory(payload):updateCategory(payload));
      props.history.goBack()
    }

    useEffect(() => {
      console.log(props.category)
      if(props.match.params.id === 'new'){
        setCreate(true)
      }
    },[props.category, props.match.params.id])

    const onChange = (event, name) => {
      console.log(event.target.value, name)
      if(allowedFields.includes(name)){
        setCategory({ ...category, [name]: event.target.value });
        console.log(disabled)
      }
    };

    return (
      <Portlet
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle="Category Information"
            title={category.name ? category.name:'Name'}
          />
        </PortletHeader>
        <PortletContent>
          <form className={classes.form}>
            <TextField
              required
              className={classes.textField}
              label="Category Name"
              name="name"
              onChange={event =>
                onChange(event, 'name')
              }
              type="text"
              value={category.name ? category.name:''}
              variant="standard"
            />
            <TextField
              className={classes.textField}
              label="Description"
              name="description"
              onChange={event =>
                onChange(event, 'description')
              }
              type="text"
              value={category.description ? category.description:''}
              variant="standard"
              multiline={true}
              rows="5"
            />
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSubmit}
            disabled={disabled}
          >
            Save
          </Button>
        </PortletFooter>
      </Portlet>
    );
}

CategoryDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CategoryDetails);
