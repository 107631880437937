import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import uuid from 'uuid'
import { S3Album } from 'aws-amplify-react'
import { Storage } from 'aws-amplify'

import styles from './styles'

const HdtvTheme = {
    album: {
      display: 'flex',
      maxWidth: '100%'
    },
    photoImg: { height: '200px' },
    pickerInput: {
        top: 0,
        left: 0,
        position: 'absolute',
        height: '45px',
        width: '100%'
    },
    photoPickerButton: {
        backgroundColor: '#000',
        top: 0, 
        left: 0,
        position: 'absolute'
    },
    overlaySelected: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#A333C8'
    }
}

const PhotoLibrary = (props) => {
  const [open, setOpen] = useState(false);
  const { onClickItem, classes } = props
  const [ selected, setSelected ] = useState([])

  function handleClickOpen() {
    setSelected([])
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleRemove(){
    selected.forEach((key) => {
      Storage.remove(key)
        .then(result => console.log(result))
        .catch(err => console.log(err))
    })
    handleClose()
  }

  function handleSelect(item) {
    setSelected([...selected, item.key])
  }

  function fileToKey(data) {
    let ext = data.name.split('.').pop();
    let key = uuid()+'.'+ext;
    return key;
  }

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Select Image
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Select Image</DialogTitle>
        <DialogContent>
          <S3Album select onSelect={handleSelect} theme={HdtvTheme} onClickItem={onClickItem} level="public" path="photos/" fileToKey={fileToKey} picker pickerTitle="Upload new file" />
        </DialogContent>
        <DialogActions>
          { selected.length > 0 && (
            <Button className={classes.deleteBtn} onClick={handleRemove} style={{color:'red'}}>
              Delete
            </Button>
          )}
          <Button onClick={handleClose} color="primary">
            Set
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default withStyles(styles)(PhotoLibrary);
