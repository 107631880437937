import React, {useState} from 'react';
import {useQuery} from "react-apollo-hooks";
import gql from "graphql-tag";
import {listProductCategorys} from "../graphql/queries";

export default function(filter, fetchPolicy='network-only') {
  const [categories, setCategories] = useState(null);
  const {data, refetch, error, fetchMore} = useQuery(gql(listProductCategorys), {fetchPolicy, variables: {limit: 1000, filter}});

  React.useEffect(() => {
    if (data.listProductCategorys){
      const _items = data.listProductCategorys.items.sort((a, b) => a.name.localeCompare(b.name));
      setCategories(_items);
    }
    if (error) {
      console.warn(error);
    }
  }, [data, error]);

  return {categories, refetch, error, fetchMore}
}
