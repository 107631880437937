export default theme => ({
  root: {},
  form: {},
  button: {},
  textField: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  portletFooter: {},
  formControl: {
    width: "100px"
  }
});
