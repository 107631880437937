import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  withStyles,
  ButtonGroup, Snackbar
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {
  LinearProgress,
  Typography,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Link,
  Button,
  IconButton
} from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";
import { DesignerToolbar } from "./components";
import { Portlet, PortletContent } from "components";
import styles from "./styles";

//graphql
import { useMutation, useQuery } from "react-apollo-hooks";
import { listDesigners } from "../../graphql/queries";
import gql from "graphql-tag";
import { updateDesigner } from "../../graphql/mutations";
import { deleteDesignerData } from "../../hooks/useDeleteDesigner";
import Tags from "../../components/Tags";
import useTags from "../../hooks/useTags";
import DialogPopup from "../../components/DialogPopup";

const getDesigners = gql`
  ${listDesigners}
`;

const GetDesignersQuery = gql`
  ${getDesigners}
`;

const DesignerList = props => {
  const { classes } = props;
  const [limit] = useState(10000);
  const [snackbar, setSnackbar] = useState({open: false, message: ''});
  const [designers, setDesigners] = useState([]);
  const [page, setPage] = useState(0);
  const [saving, setSaving] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [proOnly, setProOnly] = useState(false);
  const [selectedTags, setSlectedTags] = useState([]);
  const { tags, refetch: refetchTags } = useTags("Designer");
  const [deletingDesigner, setDeletingDesigner] = useState(null);
  const [confirmEmail, setConfirmEmail] = useState("");

  const statuses = ["All", "Active", "Inactive", "Pending"];

  const DesignerLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));
  const { data, loading, refetch } = useQuery(GetDesignersQuery, {
    variables: { limit: limit },
    fetchPolicy: "network-only"
  });

  const UpdateDesigner = useMutation(gql(updateDesigner));

  useEffect(() => {
    if (
      data.listDesigners !== null &&
      typeof data.listDesigners !== "undefined"
    ) {
      setDesigners(data.listDesigners.items);
    }
  }, [data]);

  useEffect(() => {
    if (proOnly) {
      return setPage(0);
    }
    setPage(
      window.localStorage.getItem("designerListPage") === null
        ? 0
        : window.localStorage.getItem("designerListPage")
    );
  }, [proOnly]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, designers.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    window.localStorage.setItem("designerListPage", newPage);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onDeactivateDesigner(designer) {
    return async function() {
      try {
        await UpdateDesigner({
          variables: { input: { id: designer.id, active: false } }
        });
        refetch();
        onOpenSnackbar('Disabled successfully');
      } catch (e) {
        onOpenSnackbar('Something went wrong!');
        console.log(e);
      }
    };
  }

  function onActivateDesigner(designer) {
    return async function() {
      try {
        await UpdateDesigner({
          variables: { input: { id: designer.id, active: true } }
        });
        refetch();
        onOpenSnackbar('Enabled successfully');
      } catch (e) {
        onOpenSnackbar('Something went wrong!');
        console.log(e);
      }
    };
  }

  function onDeleteDesigner(designer) {
    return function() {
      setDeletingDesigner(designer);
    };
  }

  async function deleteDesigner() {
    setSaving(true);
    setDeletingDesigner(null);
    setConfirmEmail("");
    await deleteDesignerData(deletingDesigner.id);
    refetch();
    setSaving(false);
  }

  const onFilterByStatus = status => () => {
    if (status === "all") {
      setPage(
        window.localStorage.getItem("designerListPage") === null
          ? 0
          : window.localStorage.getItem("designerListPage")
      );
    } else {
      setPage(0);
    }
    setStatus(status);
  };

  const onSearch = event => {
    setPage(0);
    setSearch(event.target.value);
  };

  function filterDesigners(q, item) {
    const _status = item.active
      ? "active"
      : item.active === null
      ? "pending"
      : "inactive";
    if (status !== "all" && _status !== status) {
      return false;
    }

    if (proOnly && !item.pro) {
      return false;
    }

    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    return (
      searchRegex.test(item.id) ||
      searchRegex.test(item.name) ||
      searchRegex.test(item.email) ||
      searchRegex.test(
        moment(item.createdAt.slice(0, 16) + "+0000")
          .tz("America/Chicago")
          .format("llll")
      )
    );
  }

  const onOpenSnackbar = (message) => setSnackbar({open: true, message});

  const onSelectedTag = tag => {
    if (selectedTags.filter(t => t.id === tag.id).length === 0) {
      setSlectedTags([...selectedTags, tag]);
    }
  };

  const onDeleteSelectedTag = tag => {
    setSlectedTags(selectedTags.filter(t => t.id !== tag.id));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.root}>
          <Tags
            tags={tags}
            type={"Designer"}
            onClick={onSelectedTag}
            refetch={refetchTags}
            onOpenSnackbar={onOpenSnackbar}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.root}>
          <DesignerToolbar
            refetch={refetch}
            statuses={statuses}
            status={status}
            filter={onFilterByStatus}
            search={onSearch}
            proOnly={proOnly}
            setProOnly={setProOnly}
          />
          <div className={classes.content}>
            {loading && (
              <div className={classes.progressWrapper}>
                <LinearProgress />
              </div>
            )}
            {designers.length === 0 && (
              <Typography variant="h6">
                There are no designers available
              </Typography>
            )}
            {designers.length > 0 && (
              <Portlet>
                <PortletContent noPadding>
                  <PerfectScrollbar>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">GUID</TableCell>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Email</TableCell>
                          <TableCell align="left">Bio</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {designers
                          .filter(designer => filterDesigners(search, designer))
                          .sort(function compare(a, b) {
                            var dateA = new Date(a.createdAt.slice(0, 16));
                            var dateB = new Date(b.createdAt.slice(0, 16));
                            return dateB - dateA;
                          })
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map(designer => (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={designer.id}
                            >
                              <TableCell className={classes.tableCell}>
                                <Link
                                  component={DesignerLink}
                                  to={"/designers/" + designer.id}
                                >
                                  {designer.id}
                                </Link>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {designer.name}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {designer.email}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {designer.bio}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Grid container item xs={12} direction={"row"}>
                                  <ButtonGroup>
                                    {!designer.active && (
                                      <Button
                                        size={"small"}
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={onActivateDesigner(designer)}
                                      >
                                        Enable
                                      </Button>
                                    )}
                                    {designer.active !== false && (
                                      <Button
                                        size={"small"}
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={onDeactivateDesigner(designer)}
                                      >
                                        {"Disable"}
                                      </Button>
                                    )}
                                  </ButtonGroup>
                                  {!designer.active && (
                                    <IconButton
                                      onClick={onDeleteDesigner(designer)}
                                    >
                                      <DeleteOutlined />
                                    </IconButton>
                                  )}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 56 * emptyRows }}>
                            <TableCell colSpan={4} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={4}
                            count={designers.length}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            page={parseInt(page)}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                            SelectProps={{
                              inputProps: { "aria-label": "Rows per page" },
                              native: true
                            }}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </PerfectScrollbar>
                </PortletContent>
              </Portlet>
            )}
          </div>
          <Snackbar open={snackbar.open} message={snackbar.message} autoHideDuration={3000} onClose={() => setSnackbar({open: false, message: ''})} />
          <DialogPopup
            open={saving}
            loading={true}
            loadingText={"Deleting..."}
          />
          {deletingDesigner && (
            <Dialog open={!!deletingDesigner}>
              <DialogTitle>
                <Typography>
                  Deleting designer? This action is undoable.
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography>Retype designer email address </Typography>
                <TextField
                  value={confirmEmail}
                  placeholder={"email"}
                  onChange={e => setConfirmEmail(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeletingDesigner(null)}>
                  Cancel
                </Button>
                <Button
                  disabled={confirmEmail !== deletingDesigner.email}
                  onClick={deleteDesigner}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

DesignerList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DesignerList);
