import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Box, IconButton, Typography, Button, withStyles, ButtonGroup} from '@material-ui/core';
import {RefreshOutlined} from '@material-ui/icons'

import {SearchInput} from 'components';
// Component styles
import styles from './styles';
import {ExpandMore, ExpandLess} from '@material-ui/icons';

class ProductCategoryToolbar extends Component {
  render() {
    const {classes, className, onNewCategory, onRefetch, status, statuses, setStatus, setSearch, toggleCategory, showCategory} = this.props;

    const rootClassName = classNames(classes.root, className);

    const onSearch = e => {
      setSearch(e.target.value)
    };

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <Typography component={'div'}><Box variant={'h1'}>Categories</Box></Typography>
          <IconButton onClick={toggleCategory}>
            {showCategory? <ExpandLess/>: <ExpandMore/>}
          </IconButton>
          <span className={classes.spacer}/>
          {showCategory && <>
            <ButtonGroup variant={'outlined'}>
              {statuses.map(stat => <Button key={stat} disabled={status===stat} onClick={() => setStatus(stat)}>{stat}</Button>)}
            </ButtonGroup>
            <IconButton onClick={onRefetch}><RefreshOutlined/></IconButton>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={onNewCategory}
            >
              Create New Category
            </Button>
          </>}
        </div>
        <div className={classes.row}>
          {showCategory && <SearchInput
            className={classes.searchInput}
            placeholder="Search product"
            onChange={onSearch}
          />}
        </div>
      </div>
    );
  }
}

ProductCategoryToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCategoryToolbar);
