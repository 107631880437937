import React, { useState, useEffect } from "react";
import {
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
  withStyles
} from "@material-ui/core";
import styles from "./styles";
import ProductCategoryToolbar from "./ProductCategoryToolbar";
import ProductCategoryCard from "./ProductCategoryCard";
import { filterItemsByName } from "../../../services";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from "@material-ui/icons";

const ProductCategory = ({
  categories,
  mainCategories,
  classes,
  onRefetch,
  refetch,
  error,
  onNewCategory,
  onDeleteCategory,
  onShowCategory,
  onEditCategory
}) => {
  const statuses = ["All", "Sub Categories", "Main Categories"];
  const [status, setStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [showCategory, setShowCategory] = useState(true);
  const [_categories, setCategories] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [total, setTotal] = useState(0);
  const storageKey = "hdtv_productCategoryListPage";

  useEffect(() => {
    if (_categories){
      const _total = _categories.filter(cat => filterItemsByName(search, cat))
        .length;
      if (search === "") {
        setTotal(_categories.length);
        setPage(
          window.localStorage.getItem(storageKey)
            ? Number(window.localStorage.getItem(storageKey))
            : 0
        );
      } else if (page * rowsPerPage > _total) {
        setPage(0);
        setTotal(_total);
      } else {
        setTotal(_total);
      }
    }
  }, [search, _categories]);

  // TODO: another dirty fix......
  useEffect(() => {
    if (categories && mainCategories) {
      setCategories([...categories, ...mainCategories]);
    } else if (categories) {
      setCategories([...categories]);
    } else if (mainCategories) {
      setCategories([...mainCategories])
    }
  }, [mainCategories, categories]);

  const filterCategory = cat => {
    if (status === "Sub Categories") {
      return !!cat.mainCategory;
    }

    if (status === "Main Categories") {
      return !cat.mainCategory;
    }
    return filterItemsByName(search, cat);
  };
  return (
    <>
      <ProductCategoryToolbar
        statuses={statuses}
        showCategory={showCategory}
        toggleCategory={() => setShowCategory(!showCategory)}
        setSearch={setSearch}
        status={status}
        setStatus={setStatus}
        onRefetch={onRefetch}
        onNewCategory={onNewCategory}
      />
      <Collapse in={showCategory}>
        {categories && categories.length > 0 ? (
          <Grid container spacing={3}>
            {categories
              .filter(category => filterCategory(category))
              .filter((_, index) => {
                return (
                  index >= page * rowsPerPage &&
                  index < (page + 1) * rowsPerPage
                );
              })
              .map(category => (
                <Grid item key={category.id} lg={4} md={6} xs={12}>
                  <ProductCategoryCard
                    onEditCategory={onEditCategory}
                    onCategoryDetail={() => onShowCategory(category)}
                    onDelete={onDeleteCategory}
                    refetch={refetch}
                    category={category}
                  />
                </Grid>
              ))}
            <Grid item xs={12} container spacing={3} className={classes.pagination}>
              <Typography variant="caption">
                {page * rowsPerPage + 1}-
                {(page + 1) * rowsPerPage < total
                  ? (page + 1) * rowsPerPage
                  : total}{" "}
                of {total}
              </Typography>
              <IconButton
                disabled={page === 0}
                onClick={() => {
                  if (page > 0) {
                    window.localStorage.setItem(
                      storageKey,
                      (page - 1).toString()
                    );
                    setPage(page - 1);
                  }
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                disabled={(page + 1) * rowsPerPage >= total}
                onClick={() => {
                  if ((page + 1) * rowsPerPage < total) {
                    window.localStorage.setItem(
                      storageKey,
                      (page + 1).toString()
                    );
                    setPage(page + 1);
                  }
                }}
              >
                <ChevronRightIcon />
              </IconButton>
              <Typography variant={"caption"}>
                Page: {page + 1}/{Math.ceil(total / rowsPerPage)}
              </Typography>
            </Grid>
          </Grid>
        ) : categories && categories.length === 0 && (
          <Paper className={classes.root}>
            <Typography variant={"caption"}>
              No product categories created
            </Typography>
          </Paper>
        )}
        {!categories && <LinearProgress />}
        {error && <Paper className={classes.root}>
          <Typography variant={"caption"}>
            Failed to fetch data
          </Typography>
        </Paper>}
      </Collapse>
    </>
  );
};

export default withStyles(styles)(ProductCategory);
