import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, IconButton, Button, Link } from '@material-ui/core';
import { SearchInput } from 'components';
import styles from './styles';
import ProgressBar from '../../../../components/ProgressBar'
import { Refresh as RefreshIcon } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom';


const PostsToolbar = (props) => {

  const { classes, className, search, filter, statuses } = props;
  const [ loaded ] = useState(null)
  const [status, setStatus] = useState('All');

  const PostLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);


  const refresh = () => {
    props.refetch()
  };

  const onFilter = (status) => () => {
    setStatus(status);
    filter(status);
  };


  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {loaded &&
        <div className={classes.row}>
          <ProgressBar progress={loaded} />
        </div>
      }
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Link component={PostLink} to={'/posts/new'}>
          <Button color="primary" size="small" variant="outlined" >
            Create New Article
          </Button>
        </Link>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search post"
          onChange={search}
        />
        <span className={classes.spacer} />
        {statuses.map(stat => <Button variant={status === stat ? 'outlined': 'default'} onClick={onFilter(stat)}>{stat}</Button>)}
        <IconButton aria-label="Add" onClick={refresh}>
          <RefreshIcon color="primary" />
        </IconButton>
        {/* <DisplayMode mode="list" /> */}
      </div>
    </div>
  );
}

PostsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PostsToolbar);
