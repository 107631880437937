import React, {useContext, useState} from "react";
import { ButtonGroup, Button, withStyles } from "@material-ui/core";

import Profile from "./components/Profile";
import styles from "./styles";
import Portlet from "../../Portlet";
import PortletHeader from "../../PortletHeader";
import PortletContent from "../../PortletContent";
import {DesignerContext} from "../../../views/Designers/components/DesignerDetail/components/DesignerDetails/DesignerDetails";
import DialogPopup from "../../DialogPopup";
import {updateDesignerData} from "../../../hooks/useUpdateDesigner";

const PublicProfile = ({ classes }) => {
  const [hide, setHide] = useState(true);
  const [dialog, setDialog] = useState({});

  const {designer, refetch} = useContext(DesignerContext);

  React.useEffect(() => console.log(designer), [designer]);

  const openDialog = (title, content, loading = false) => {
    setDialog({ open: true, title, content, loading });
  };

  const onPublishProfile = async () => {
    try {
      openDialog('Saving...', '', true);

      const data = Object.assign({}, designer.draft_profile);
      delete data.__typename;

      await updateDesignerData(designer.id, null, null, {
        bio: data.bio,
        photo_url: data.photo_url,
        name: data.name,
        profile: { ...data, status: 'published' },
        draft_profile: { ...data, status: 'published' }
      });

      refetch();

      openDialog('Saved!');
    } catch (e) {
      console.log(e);
      openDialog('Something went wrong! Please try again!');
    }
  };

  return (
    <Portlet>
      <PortletHeader>
        <ButtonGroup>
        <Button variant={"outlined"} onClick={() => setHide(!hide)}>
          {hide ? "Edit Public Profile" : "Close"}
        </Button>
        {designer.draft_profile && designer.draft_profile.status !== 'published' && !hide && <Button variant={'outlined'} onClick={onPublishProfile}>Publish Profile</Button>}
        </ButtonGroup>
      </PortletHeader>
      {!hide && (
        <PortletContent>
          <Profile classes={classes} />
          {dialog.open && <DialogPopup open={true} loading={dialog.loading} onClose={() => setDialog({})} loadingText='' title={dialog.title}/>}
        </PortletContent>
      )}
    </Portlet>
  );
};

export default withStyles(styles)(PublicProfile);
