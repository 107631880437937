import React, { useState, useEffect } from 'react';
import { Avatar, Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import { DropzoneArea } from 'material-ui-dropzone';
import uuid from 'uuid';
import { Storage } from 'aws-amplify';
import ProgressBar from '../../components/ProgressBar';

const UploadPhotoComponent = ({ designer, type, classes, onDone, ...restProps }) => {
  const [loaded, setLoaded] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let img_url = null;

    switch (type) {
      case 'profile':
        img_url = designer.draft_profile ? designer.draft_profile.photo_url : '';
        break;
      case 'cover':
        img_url = designer.draft_profile ? designer.draft_profile.cover_url : '';
        break;
      default:
        img_url = null;
    }

    if (img_url) setImageUrl(img_url);
  }, [onDone, type, designer]);

  const uploadFile = file => {
    const ext = file.name.split('.').pop();
    const key = uuid() + '.' + ext;
    Storage.put('images/' + key, file, {
      bucket: 'hdtv-prod',
      level: 'public',
      progressCallback(progress) {
        setLoaded(progress);
      }
    }).then(res => {
      removePhoto();
      if (onDone) onDone(res.key);
    });
  };

  const removePhoto = () => {
    if (imageUrl) {
      Storage.remove(imageUrl, {
        bucket: 'hdtv-prod',
        level: 'public'
      })
        .then(() => true)
        .catch(err => new Error(err));
    } else {
      return true;
    }
  };

  const onFileSelected = file => {
    uploadFile(file);
  };

  return (
    <>
      <Grid container alignItems={'center'} justify={'center'}>
        <Avatar
          src={imageUrl ? `https://hdtv-prod.s3.amazonaws.com/public/${imageUrl}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
          style={{ margin: 10, width: 300, height: 300 }}
        />
      </Grid>
      <Grid container alignItems={'center'} justify={'center'} spacing={10}>
        {loaded && (
          <Grid item xs={12}>
            <ProgressBar progress={loaded} />
          </Grid>
        )}
        {!loaded && (
          <Grid item xs={12}>
            <DropzoneArea className={classes.dropZone} filesLimit={1} onDrop={onFileSelected} maxFileSize={10000000} acceptedFiles={['image/*']} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles)(UploadPhotoComponent);
