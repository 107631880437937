import {useState, useEffect} from 'react';
import {useQuery} from "react-apollo-hooks";
import gql from "graphql-tag";
import {getTag, listTags} from "../graphql/queries";
import {createTag, createTagsVideos, deleteTag, deleteTagsDesigners, deleteTagsVideos} from "../graphql/mutations";

export default function useTags (type='Video') {
  const [tags, setTags] = useState(null);
  const {data, refetch, loading, error} = useQuery(gql(listTags), {fetchPolicy: 'network-only', variables: {limit: 1000, filter: {type: {eq: type}}}});

  useEffect(() => {
    if (error) {
      throw error;
      console.log(error);
    }

    if (data.listTags) {
      setTags(data.listTags.items);
    }
  }, [data, error]);

  return {tags, refetch, loading}
}

export const onGetTag = (client, id) => {
    return client.query({
      query: gql(getTag),
      variables: {id}
    })
};

export const onDeleteTagVideo = (client, id) => {
    return client.mutate({
      mutation: gql(deleteTagsVideos),
      variables: {input: {id}}
    })
};

export const onDeleteTagDesigner = (client, id) => {
  return client.mutate({
    mutation: gql(deleteTagsDesigners),
    variables: {input: {id}}
  })
};

export const onCreateTag = (client, payload) => {
    return client.mutate({
      mutation: gql(createTag),
      variables: payload,
    })
};

export const onDeleteTag = (client, id) => {
    return client.mutate({
      mutation: gql(deleteTag),
      variables: {input: {id}}
    })
};

export const onCreateTagsVideosData = (client, tagId, videoId ) => {
    return client.mutate({
      mutation: gql(createTagsVideos),
      variables: {input: {tagsVideosTagId: tagId, tagsVideosVideoId: videoId}}
    })
};

export const onRemoveTagsVideosData = (client, id) => {
    return client.mutate({
      mutation: gql(deleteTagsVideos),
      variables: {input: {id}}
    })
};
