import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  LinearProgress,
  Typography,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Link
} from '@material-ui/core';
import { ShowToolbar } from './components';
import { Portlet, PortletContent } from 'components';
import styles from './styles';

//graphql
import { useQuery } from 'react-apollo-hooks';
import { listShows } from '../../graphql/queries';
import gql from 'graphql-tag';

const getShows = gql`${listShows}`;

const GetShowsQuery = gql`
  ${getShows}
`;

const ShowList = props => {
  const { classes } = props;
  const [limit] = useState(10000);
  const [shows, setShows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search,setSearch] = useState('')

  const ShowLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

  const { data, loading, refetch } = useQuery(GetShowsQuery, {
    variables: { limit: limit },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (
      data.listShows !== null &&
      typeof data.listShows !== 'undefined'
    ) {
      setShows(data.listShows.items);
      //setLimit((data.listHdtvShows.items.length > limit) ? limit:data.listHdtvShows.items.length)
    }
  }, [data]);

  useEffect(() => {
    setPage(window.localStorage.getItem('showListPage') === null ? 0:window.localStorage.getItem('showListPage'))
  },[])

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, shows.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    window.localStorage.setItem('showListPage',newPage)
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onSearch = (event) => {
    setPage(0)
    setSearch(event.target.value)
  }

  function filterShows(q, item) {
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    return ( searchRegex.test(item.id) || searchRegex.test(item.title) || searchRegex.test(item.description) || searchRegex.test(moment(item.createdAt.slice(0, 16) + '+0000')
    .tz('America/Chicago')
    .format('llll')));
  }

  return (
      <div className={classes.root}>
        <ShowToolbar refetch={refetch} search={onSearch}/>
        <div className={classes.content}>
          {loading && (
            <div className={classes.progressWrapper}>
              <LinearProgress />
            </div>
          )}
          {shows.length === 0 && (
            <Typography variant="h6">There are no shows available</Typography>
          )}
          {shows.length > 0 && (
            <Portlet >
              <PortletContent noPadding>
                <PerfectScrollbar>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">GUID</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">Desc</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shows
                      .filter(show => filterShows(search,show))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(show => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={show.id}>
                          <TableCell className={classes.tableCell}>
                            <Link component={ShowLink} to={'/shows/'+show.id}>
                              {show.id}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {show.title}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {show.description}
                          </TableCell>
                        </TableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 56 * emptyRows }}>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={4}
                        count={shows.length}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        page={parseInt(page)}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        SelectProps={{
                          inputProps: { 'aria-label': 'Rows per page' },
                          native: true
                        }}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </PerfectScrollbar>
            </PortletContent>
          </Portlet>
          )}
        </div>
      </div>
  );
};

ShowList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ShowList);
