import { Button, FormControl, Grid, TextField, InputLabel, Typography, FormLabel, FormControlLabel, FormGroup, Checkbox, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const BioInformation = ({ onSubmit, classes, designer }) => {
  const [bio, setBio] = useState(null);
  const [availables, setAvailables] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  useEffect(() => {
    if (designer.id) {
      console.log('designer data: ', designer);
      setBio(designer.draft_profile && designer.draft_profile.bio ? designer.draft_profile.bio : designer.bio? designer.bio:  '');
      setAvailables(designer.draft_profile && designer.draft_profile.availables ? designer.draft_profile.availables : []);
      setCertifications(designer.draft_profile && designer.draft_profile.certifications ? designer.draft_profile.certifications : []);
    }
  }, [designer]);

  const onAvailableChange = value => () => {
    if (availables.indexOf(value) < 0) {
      setAvailables([...availables, value]);
    } else {
      setAvailables(availables.filter(e => e !== value));
    }
  };

  const onCertificationChange = value => () => {
    if (certifications.indexOf(value) < 0) {
      setCertifications([...certifications, value]);
    } else {
      setCertifications(certifications.filter(e => e !== value));
    }
  };

  const contains = (arr, value) => {
    return arr.indexOf(value) >= 0;
  };

  const onSaveChanges = () => {
    const data = { bio: bio ? bio : '', availables: availables ? availables : [], certifications: certifications ? certifications : [] };
    onSubmit(data);
  };

  return bio || bio === '' ? (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.centered}>
        <Typography variant={'h2'} color={'textPrimary'}>
          Biographical Information
        </Typography>
        <Typography color={'textSecondary'}>Give some information on who you are, your background in design, and what type of projects you enjoy working on</Typography>
      </Grid>
      <Grid container alignItems={'center'} justify={'center'}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Brief Biography</InputLabel>
          <TextField multiline rows={5} id="bio" className={classes.textField} margin="normal" variant="outlined" value={bio} onChange={e => setBio(e.target.value)} />
        </FormControl>
      </Grid>
      <Grid item xs={mobile ? 12 : 6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Available For</FormLabel>
          <FormGroup>
            <Grid container spacing={3}>
              <Grid item xs={6} className={classes.checkboxes}>
                <FormControlLabel control={<Checkbox checked={contains(availables, 'residential')} onChange={onAvailableChange('residential')} />} label="RESIDENTIAL" />
                <FormControlLabel control={<Checkbox checked={contains(availables, 'outdoor')} onChange={onAvailableChange('outdoor')} />} label="OUTDOOR" />
                <FormControlLabel control={<Checkbox checked={contains(availables, 'remodel')} onChange={onAvailableChange('remodel')} />} label="REMODEL" />
                <FormControlLabel control={<Checkbox checked={contains(availables, 'medical')} onChange={onAvailableChange('medical')} />} label="MEDICAL" />
                <FormControlLabel control={<Checkbox checked={contains(availables, 'staging')} onChange={onAvailableChange('staging')} />} label="STAGING" />
              </Grid>
              <Grid item xs={6} className={classes.checkboxes}>
                <FormControlLabel control={<Checkbox checked={contains(availables, 'commercial')} onChange={onAvailableChange('commercial')} />} label="COMMERCIAL" />
                <FormControlLabel control={<Checkbox checked={contains(availables, 'construction')} onChange={onAvailableChange('construction')} />} label="CONSTRUCTION" />
                <FormControlLabel control={<Checkbox checked={contains(availables, 'hospitality')} onChange={onAvailableChange('hospitality')} />} label="HOSPITALITY" />
                <FormControlLabel control={<Checkbox checked={contains(availables, 'showroom')} onChange={onAvailableChange('showroom')} />} label="SHOWROOM" />
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
      {designer.id && !designer.pro && (
        <Grid item xs={mobile ? 12 : 6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">My Certifications and Memberships</FormLabel>
            <FormGroup>
              <Grid container spacing={3}>
                <Grid item xs={6} className={classes.checkboxes}>
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'ASID')} onChange={onCertificationChange('ASID')} />} label="ASID" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'IIDA')} onChange={onCertificationChange('IIDA')} />} label="IIDA" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'IDS')} onChange={onCertificationChange('IDS')} />} label="IDS" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'IDC')} onChange={onCertificationChange('IDC')} />} label="IDC" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'BIID')} onChange={onCertificationChange('BIID')} />} label="BIID" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'AIA')} onChange={onCertificationChange('AIA')} />} label="AIA" />
                </Grid>
                <Grid item xs={6} className={classes.checkboxes}>
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'NCIDQ')} onChange={onCertificationChange('NCIDQ')} />} label="NCIDQ" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'AID')} onChange={onCertificationChange('AID')} />} label="AID" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'IDEC')} onChange={onCertificationChange('IDEC')} />} label="IDEC" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'DSA')} onChange={onCertificationChange('DSA')} />} label="DSA" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'LEED')} onChange={onCertificationChange('LEED')} />} label="LEED" />
                  <FormControlLabel control={<Checkbox checked={contains(certifications, 'RA')} onChange={onCertificationChange('RA')} />} label="RA" />
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
      )}
      <Grid container alignItems={'center'} justify={'center'}>
        <Button variant={'contained'} color={'primary'} onClick={onSaveChanges}>
          Save Changes
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Typography>Loading...</Typography>
  );
};

export default BioInformation;
