import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { LinearProgress, Typography } from "@material-ui/core";

// Shared services
import { UsersToolbar, UsersTable } from "./components";

// Component styles
import styles from "./style";
import {useQuery} from "react-apollo-hooks";
import gql from "graphql-tag";
import {listDesigners, listUsers} from "../../graphql/queries";

const UserList = ({ classes }) => {
  const [signal] = useState(true);
  const [search, setSearch] = useState('');

  const [state, setState] = useState({
    isLoading: false,
    limit: 10000,
    error: null
  });

  const {data, refetch} = useQuery(gql(listUsers), {variables: {limit: state.limit}, fetchPolicy: 'network-only'});
  const {data: designersData, refetch: refetchDesigner} = useQuery(gql(listDesigners), {variables: {limit: state.limit}, fetchPolicy: 'network-only'});

  const [users, setUsers] = useState([]);
  const [designers, setDesigners] = useState([]);

  const [selectedUsers, setSelectedUser] = useState([]);

  React.useEffect(()=> {
    console.log('users: ', data);
    if (data && data.listUsers) {
      setUsers(data.listUsers.items);
    }
  }, [data]);

  React.useEffect(() => {
    console.log('desigenrs: ', designersData);
    if (designersData && designersData.listDesigners) {
      setDesigners(designersData.listDesigners.items);
    }
  });

  const handleSelect = selectedUsers => {
    setSelectedUser(selectedUsers);
  };

  const onSetDesigner = e => {
    console.log('set designer: ', e);
  };

  const renderUsers = () => {
    const { isLoading, error } = state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <LinearProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (users.length === 0) {
      return <Typography variant="h6">There are no users</Typography>;
    }

    return <UsersTable onSetDesigner={onSetDesigner} search={search} onSelect={handleSelect} users={users} designers={designers} />;
  };

  return (
    <div className={classes.root}>
      <UsersToolbar setSearch={setSearch} selectedUsers={selectedUsers} />
      <div className={classes.content}>{renderUsers()}</div>
    </div>
  );
};

UserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
