import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './common/serviceWorker';

//amplify
import Amplify, { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { Rehydrated } from 'aws-appsync-react';

import awsconfig from './aws-exports';

import PubSub from '@aws-amplify/pubsub';

PubSub.configure();

// retrieve temporary AWS credentials and sign requests
Amplify.configure(awsconfig);

const client = new AWSAppSyncClient({
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
    },
    disableOffline: false
  });

const WithProvider = () => (
  <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
          <Rehydrated>
              <App />
          </Rehydrated>
      </ApolloHooksProvider>
  </ApolloProvider>
)

ReactDOM.render(<WithProvider />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
