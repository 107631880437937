import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogTitle,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  Box,
  Checkbox,
  Select,
  InputLabel,
  withStyles,
  Snackbar,
  DialogActions,
  Button,
  FormControlLabel
} from "@material-ui/core";

import { useApolloClient } from "react-apollo-hooks";
import gql from "graphql-tag";
import {
  createProductCategory,
  updateProductCategory
} from "../../../../graphql/mutations";

import { uploadFile, removeFile } from "../../../../services/storage";

import styles from "./styles";

const NewProductCategoryModal = ({
  open,
  category,
  categories,
  onClose,
  classes
}) => {
  const [state, setState] = useState({
    name: "",
    slug: "",
    image: " ",
    imageFile: null
  });
  const client = useApolloClient();
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [mainCategoryId, setMainCategoryId] = useState("");
  const [isSubCategory, setIsSubCategory] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (category) {
      console.log('category data: ', category);
      setState({
        name: category.name,
        slug: category.slug,
        image: category.image,
        imageFile: null
      });
      if (category.productCategoryMainCategoryId) {
        setIsSubCategory(true);
        setMainCategoryId(category.productCategoryMainCategoryId);
      }
    }
  }, [category]);

  const onSubmit = async () => {
    if (state.name.length < 2) {
      return setSnackbar({ open: true, message: "Name is required" });
    }

    if (state.slug.length < 2) {
      return setSnackbar({ open: true, message: "Slug is required" });
    }

    if (isSubCategory && mainCategoryId === "") {
      return setSnackbar({ open: true, message: "Choose main category!" });
    }

    try {
      setDisabled(true);
      const payload = {
        name: state.name,
        slug: state.slug,
        productCategoryMainCategoryId: isSubCategory ? mainCategoryId : ""
      };

      if (state.imageFile) {
        if (state.image && state.image.length > 1) {
          await removeFile(state.image);
        }

        const res = await uploadFile(state.imageFile);
        payload.image = res.key;
      } else if (state.image && state.image.length > 1) {
        payload.image = state.image;
      }

      if (category) {
        payload.id = category.id;
        await client.mutate({
          mutation: gql(updateProductCategory),
          variables: { input: payload }
        });
      } else {
        await client.mutate({
          mutation: gql(createProductCategory),
          variables: { input: payload }
        });
      }
      setIsSubCategory(false);
      setMainCategoryId("");
      setSnackbar({ open: true, message: "Saved" });
      setState({ name: "", slug: "", image: " ", imageFile: null });
      setDisabled(false);
      onClose();
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen={mobile}>
        <DialogTitle>
          <Typography component={"div"}>
            <Box variant={"h1"}>
              {category ? "Edit" : "New"} Product Category
            </Box>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={disabled}
                label={"Name"}
                fullWidth
                value={state.name}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    name: e.target.value,
                    slug: e.target.value
                      .split(" ")
                      .join("_")
                      .toLowerCase()
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={disabled}
                label={"Slug"}
                fullWidth
                value={state.slug}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    slug: e.target.value
                      .split(" ")
                      .join("_")
                      .toLowerCase()
                  })
                }
              />
            </Grid>
            {state.image && state.image.length > 1 && (
              <Grid item xs={12}>
                <img src={state.image} alt={""} />
              </Grid>
            )}
            <Grid item xs={12}>
              <input
                disabled={disabled}
                type={"file"}
                accept={"image/*"}
                onChange={e => {
                  setState({ ...state, imageFile: e.target.files[0] });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={isSubCategory}
                    value={isSubCategory}
                    onChange={event => setIsSubCategory(event.target.checked)}
                  />
                }
                label={"Is sub-category"}
              />
            </Grid>
            {isSubCategory && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Main category: </InputLabel>
                  <Select
                    value={mainCategoryId}
                    onChange={e => setMainCategoryId(e.target.value)}
                  >
                    {categories.map(cat => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={onSubmit}>
            Submit
          </Button>
          <Button
            disabled={disabled}
            onClick={() => {
              setIsSubCategory(false);
              setMainCategoryId("");
              setState({
                name: "",
                slug: "",
                image: " ",
                imageFile: null
              });
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ open: false, message: "" })}
      />
    </>
  );
};

export default withStyles(styles)(NewProductCategoryModal);
