import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {IconButton, withStyles} from '@material-ui/core';
import { Typography, Divider } from '@material-ui/core';

// Material icons
import {
  AccessTime as AccessTimeIcon,
  EditOutlined,
  DeleteOutlined,
  GetApp as GetAppIcon
} from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class ProductCard extends Component {
  render() {
    const { classes, className, product, onEditProduct, onDeleteProduct} = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper className={rootClassName}>
        <div className={classes.imageWrapper}>
          <img
            alt=""
            className={classes.image}
            src={product.image ? product.image: ''}
          />
        </div>
        <div className={classes.details}>
          <Typography
            className={classes.title}
            variant="h4"
          >
            {product.name}
          </Typography>
          <Typography
            className={classes.description}
            variant="body1"
          >
            {product.description}
          </Typography>
        </div>
        <Divider />
        <div className={classes.stats}>
          <AccessTimeIcon className={classes.updateIcon} />
          <Typography
            className={classes.updateText}
            variant="body2"
          >
            Updated 2hr ago
          </Typography>
          <IconButton className={classes.downloadsIcon} onClick={() => onDeleteProduct(product)}>
            <DeleteOutlined/>
          </IconButton>
          <IconButton className={classes.downloadsIcon} onClick={() => onEditProduct(product)}>
            <EditOutlined />
          </IconButton>
          {product.category && <Typography variant={'body2'} className={classes.updateText}>Category: {product.category.name}</Typography>}
        </div>
      </Paper>
    );
  }
}

ProductCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCard);
