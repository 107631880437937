import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  withStyles,
  Button, useMediaQuery, useTheme, Paper, Grid
} from "@material-ui/core";

import {AccessTime as AccessTimeIcon, RemoveOutlined} from "@material-ui/icons";
import styles from "../ProductCategoryCard/styles";

const ProductCategoryDetailModal = ({rootClassName, category, open, classes, onClose, onRemove}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const renderProducts = (products) => {
    if (products.length === 0) {
      return (
        <Typography variant="h6">There are no products available</Typography>
      );
    }

    const  onRemoveProductFromCategory = async product => {
      await onRemove(product);
      category.products.items = products.filter(p => p.id !== product.id);
    };

    return (
      <Grid
        container
        spacing={3}
      >
        {products.map(product => (
          <Grid
            item
            key={product.id}
            lg={4}
            md={6}
            xs={12}
          >
            <Paper className={rootClassName}>
              <div className={classes.imageWrapper}>
                <img
                  alt="Product"
                  className={classes.image}
                  src={product.image}
                />
              </div>
              <div className={classes.details}>
                <Typography
                  className={classes.title}
                  variant="h4"
                >
                  {product.name}
                </Typography>
                <Typography
                  className={classes.description}
                  variant="body1"
                >
                  {product.description}
                </Typography>
              </div>
              <Divider />
              <div className={classes.stats}>
                <AccessTimeIcon className={classes.updateIcon} />
                <Typography
                  className={classes.updateText}
                  variant="body2"
                >
                  Updated 2hr ago
                </Typography>
                <IconButton className={classes.downloadsIcon} onClick={() => onRemoveProductFromCategory(product)}>
                  <RemoveOutlined />
                </IconButton>
                {product.category && <Typography variant={'body2'} className={classes.updateText}>Category: {product.category.name}</Typography>}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (<Dialog open={open} fullScreen={mobile}>
        <DialogTitle>
          {category.name}
          {/*<IconButton onClick={onClose}><CloseOutlined/></IconButton>*/}
        </DialogTitle>
      <DialogContent>
      <div className={classes.root}>
        <div className={classes.imageWrapper}>
          {category.image ? <img
            alt="Product"
            className={classes.image}
            src={`https://hdtv-prod.s3.amazonaws.com/public/${category.image}`}
          /> : <Typography variant={'caption'}>No image</Typography>}
        </div>
        <div className={classes.details}>
          <Typography
            className={classes.title}
            variant="h4"
          >
            {category.name}
          </Typography>
          <Typography
            className={classes.description}
            variant="body1"
          >
            {category.slug}
          </Typography>
        </div>
        <Divider/>
        <div className={classes.stats}>
          {renderProducts(category.products.items)}
        </div>
      </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant={'outlined'}>Close</Button>
      </DialogActions>
    </Dialog>
  )
};

export default withStyles(styles)(ProductCategoryDetailModal);
