import React, { useState } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Chart } from "react-chartjs-2";
import { Snackbar } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { chartjs } from "./helpers";
import theme from "./theme";
import "./App.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import Routes from "./Routes";
import { withAuthenticator } from "aws-amplify-react";
import { Dashboard as DashboardLayout } from "layouts";
import { Auth } from "aws-amplify";

// Browser history
const browserHistory = createBrowserHistory();

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

const HdtvTheme = {
  button: {
    backgroundColor: `#A333C8`
  },
  signInButton: {
    backgroundColor: `#A333C8`
  },
  signInButtonIcon: {
    backgroundColor: `#A333C8`
  },
  signInButtonContent: {
    backgroundColor: `#A333C8`
  },
  navButton: {
    backgroundColor: `#A333C8`
  },
  a: {
    color: "#A333C8"
  }
};

const App = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  React.useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(authData => {
      if (
        authData.signInUserSession.accessToken.payload[
          "cognito:groups"
        ].indexOf("Managers") < 0
      ) {
        setOpenSnackbar(true);
        setTimeout(() => {
          Auth.signOut();
        }, 2000);
      }
    });
  }, []);

  return (
    <>
      <Snackbar
        open={openSnackbar}
        message="You're not a manager. Signing out..."
      />
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <DashboardLayout title="Dashboard">
            <Routes />
          </DashboardLayout>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default withAuthenticator(App, {
  usernameAttributes: "email",
  theme: HdtvTheme
});
