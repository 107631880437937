import {API, graphqlOperation} from 'aws-amplify'
import {
  deleteDesigner,
  deletePendingVideo,
  deletePostEditor,
  deleteProject,
  deleteSub,
  deleteVideoCast, updateUser
} from "../graphql/mutations";
import {getDesigner} from "../graphql/queries";

export const deleteDesignerData = async (id) => {
    let res = await API.graphql(graphqlOperation(getDesigner, { id }));
    if (res && res.data.getDesigner.id) {
      const designer = res.data.getDesigner;

      for (let post of designer.posts.items){
        await API.graphql(graphqlOperation(deletePostEditor, {input: {id: post.id}}));
      }

      for (let cast of designer.videos.items) {
        await API.graphql(graphqlOperation(deleteVideoCast, {input: {id: cast.id}}));
      }

      for (let video of designer.pendingVideos.items) {
        await API.graphql(graphqlOperation(deletePendingVideo, {input: {id: video.id}}));
      }

      for (let sub of designer.subs.items) {
        await API.graphql(graphqlOperation(deleteSub, {input: {subDesignerId: sub.subDesignerId, createdAt: sub.createdAt}}));
      }

      for (let project of designer.projects.items) {
        await API.graphql(graphqlOperation(deleteProject, {input: {id: project.id}}));
      }

      if (designer.user) {
        await API.graphql(graphqlOperation(updateUser, {input: {id: designer.user.id, userDesignerId: null }}));
      }

      return await API.graphql(graphqlOperation(deleteDesigner, { input: { id } }));
    }
};
