import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid, LinearProgress } from '@material-ui/core';
import { ShowDetails } from './components'
import uuid from 'uuid'

//graphql stuff
import gql from 'graphql-tag'
import { getShow } from 'graphql/queries'
import { useQuery } from 'react-apollo-hooks'

const getShowQuery = gql`${getShow}`

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    paddingBottom: '5px'
  }
});

const ShowDetail = (props) => {
  const { classes } = props
  const { data, loading } = useQuery(getShowQuery, { variables: { id: props.match.params.id }})
  const [ show, setShow ] = useState({id: uuid(),title:'',description:'',release_date:new Date()})

  useEffect(() => {
    if(data.getShow && data.getShow !== 'undefined') setShow(data.getShow)
  },[data])
  
  return (
    <div className={classes.root}>
        {( (loading || show.title === '') && props.match.params.id !== 'new') ? (
          <div className={classes.progressWrapper}>
            <LinearProgress />
          </div>
        ):(
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <ShowDetails show={show} {...props}/>
          </Grid>
        </Grid>
        )}
    </div>
  );
}

ShowDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ShowDetail);