import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';

// Material icons
import {
  VideoLibraryOutlined as VideoIcon,
  TextFields as TextFieldsIcon,
  AccountBoxOutlined as AccountBoxIcon,
  CategoryOutlined as CategoryIcon,
  VideocamOutlined as VideoCamIcon,
  GroupOutlined as GroupIcon,
  ShoppingBasketOutlined as ShoppingBasketIcon
} from '@material-ui/icons';

// Component styles
import styles from './styles';

class Sidebar extends Component {
  render() {
    const { classes, className, onToggleSidebar } = this.props;

    const rootClassName = classNames(classes.root, className);
    const DashboardLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link
            className={classes.logoLink}
            to="/"
            onClick={onToggleSidebar}
          >
            <img
              alt="HDTV logo"
              className={classes.logoImage}
              src="/images/logos/logo.png"
              style={{height:30}}
            />
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        <div className={classes.profile}>
          {/* <Link to="/account">
            <Avatar
              alt="Roman Kutepov"
              className={classes.avatar}
              src="/images/avatars/avatar_1.png"
            />
          </Link> */}
          <Typography
            className={classes.nameText}
            variant="h6"
          >
            Welcome to HDTV
          </Typography>
          <Typography
            className={classes.bioText}
            variant="caption"
          >
            Administrator
          </Typography>
        </div>
        <Divider className={classes.profileDivider} />
        <List
          component="div"
          disablePadding
        >
          {/* <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            to="/dashboard"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={UserLink}
            to="/users"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Users"
            />
          </ListItem> */}
          {/*<ListItem*/}
          {/*  activeClassName={classes.activeListItem}*/}
          {/*  className={classes.listItem}*/}
          {/*  component={DashboardLink}*/}
          {/*  button*/}
          {/*  onClick={onToggleSidebar}*/}
          {/*  to="/users"*/}
          {/*>*/}
          {/*  <ListItemIcon className={classes.listItemIcon}>*/}
          {/*    <GroupIcon />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText*/}
          {/*    classes={{ primary: classes.listItemText }}*/}
          {/*    primary="User"*/}
          {/*  />*/}
          {/*</ListItem>*/}
           <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            button
            onClick={onToggleSidebar}
            to="/posts"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <TextFieldsIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Articles"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            button
            onClick={onToggleSidebar}
            to="/categories"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Categories"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            button
            onClick={onToggleSidebar}
            to="/designers"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Designers"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            button
            onClick={onToggleSidebar}
            to="/products"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Products"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            button
            onClick={onToggleSidebar}
            to="/shows"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <VideoCamIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Shows"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            button
            onClick={onToggleSidebar}
            to="/videos"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <VideoIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Videos"
            />
          </ListItem>
          {/*
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={AuthLink}
            to="/sign-in"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LockOpenIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Authentication"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={TypeLink}
            to="/typography"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <TextFieldsIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Typography"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={IconLink}
            to="/icons"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Icons and Images"
            />
          </ListItem> */}
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            to="/account"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Account"
            />
          </ListItem>
          {/* <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={SettingsLink}
            to="/settings"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Settings"
            />
          </ListItem> */}
        </List>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
