import React, { useState } from 'react'
import { withStyles, Table, TableHead, TableCell, TableRow, TableBody, FormControl, InputLabel, Select, MenuItem, Button, FormGroup } from '@material-ui/core'
import classNames from 'classnames';
import { Portlet } from 'components';
//graphql
import { useMutation, useQuery } from 'react-apollo-hooks';
import { createVideoCast, deleteVideoCast } from 'graphql/mutations';
import { listDesigners } from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import gql from 'graphql-tag';
import uuid from 'uuid'
import styles from './styles';

const GetDesignersQuery = gql`${listDesigners}`
const CreateVideoCastQuery = gql`${createVideoCast}`

const VideoCast = (props) => {
    const [ video, setVideo ] = useState({...props.video})
    const { data } = useQuery(GetDesignersQuery, { fetchPolicy: 'network-only', variables: {limit: 100000}});
    const [ designer, setDesigner ] = useState([])
    const { classes, className, ...rest } = props;
    const rootClassName = classNames(classes.root, className);

    const onChange = (event) => {
        let id = event.target.value
        let name = event.nativeEvent.target.innerText
        console.log(video)
        setDesigner({id:id, designer: { name: name }})
    }

    const CreateVideoCast = useMutation(CreateVideoCastQuery)

    const createVC = async () => {
        let videoCastDetails = {
          id: uuid(),
          videoCastDesignerId: designer.id,
          videoCastVideoId: video.id
        };
        console.log(videoCastDetails)
        CreateVideoCast( { variables: { input: videoCastDetails } } );
        setVideo({...video, designers: { ...video.designers, items: [ ...video.designers.items, designer ]}})
        console.log(video)
    }

    const deleteVC= async (id) => {
        console.log(id)
        await API.graphql(graphqlOperation(deleteVideoCast, { input: { id: id } } ))
        let designers = video.designers.items.filter(function(item) {
            return item.id !== id;
        });
        setVideo({...video, designers: { ...video.designers, items: designers }})
    }

    return (
        <Portlet
        {...rest}
        className={rootClassName}
        >
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={3}>
                        <FormGroup row={true}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="status-simple">Add a Designer</InputLabel>
                                <Select
                                value={designer.id}
                                onChange={event => onChange(event)}
                                inputProps={{
                                    name: 'designer',
                                    id: 'designer-simple',
                                }}
                                >
                                    {data && data.listDesigners && (
                                        data.listDesigners.items.map((designer) => {
                                            return <MenuItem key={designer.id} value={designer.id} >{designer.name}</MenuItem>
                                        })
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <Button onClick={createVC} variant="outlined" color="primary" style={{margin:15}}>Add</Button>
                            </FormControl>
                        </FormGroup>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>UUID</TableCell>
                    <TableCell>Designer Name</TableCell>
                    <TableCell>Remove</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {video.designers.items.length > 0 && (
                video.designers.items.map((designer) => {
                    return (
                    <TableRow key={designer.id} className={classes.TableRow}>
                        <TableCell>{designer.id}</TableCell>
                        <TableCell>{designer.designer.name}</TableCell>
                            <TableCell>
                            <Button variant="outlined" color="primary" className={classes.button} onClick={() => deleteVC(designer.id)}>
                                Remove
                            </Button>
                            </TableCell>
                    </TableRow>
                    )
                })
                )}
            </TableBody>
        </Table>
        </Portlet>
    )
}
export default withStyles(styles)(VideoCast);
