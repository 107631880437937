import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles, TextField, FormControl, Select, MenuItem, InputLabel, FormGroup, Grid,
  Button, Chip
} from '@material-ui/core';

//graphql
import {useApolloClient, useMutation, useQuery} from 'react-apollo-hooks';
import gql from 'graphql-tag';
import {updateVideo} from 'graphql/mutations';
import {listCategorys, listShows} from 'graphql/queries';
import {API, graphqlOperation} from 'aws-amplify';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Component styles
import styles from './styles';
import useTags, {onCreateTagsVideosData, onRemoveTagsVideosData} from "../../../../../../hooks/useTags";

const UpdateVideoQuery = gql`${updateVideo}`
const GetCategoriesQuery = gql`${listCategorys}`


const VideoDetails = ({refetch, ...props}) => {
  const {tags} = useTags();
  const [videoTags, setVideoTags] = useState(props.video.tags.items);
  const client = useApolloClient();
  const [video, setVideo] = useState({...props.video})
  const {data} = useQuery(GetCategoriesQuery)
  const [shows, setShows] = useState([])
  const {classes, className, ...rest} = props;
  const rootClassName = classNames(classes.root, className);
  const [tagId, setTagId] = useState('');

  const updateVideo = useMutation(UpdateVideoQuery);

  useEffect(() => {
    async function fetchShows() {
      let data = await API.graphql(graphqlOperation(listShows));
      setShows(data.data.listShows.items)
    }
    fetchShows()
  }, [video]);

  useEffect(() => setVideoTags(props.video.tags.items), [props.video.tags]);

  const allowedFields = ['id', 'title', 'description', 'url', 'thumbnail', 'status', 'videoCategoryId', 'videoShowId', 'season', 'episode', 'duration'];
  const handleSubmit = () => {
    const input = Object.keys(video).reduce((object, key) => {
      if (allowedFields.includes(key) && video[key] !== null) {
        object[key] = video[key];
      }
      return object;
    }, {});
    const payload = {
      variables: {
        input: input
      }
    };
    updateVideo(payload);
  };

  const onChange = (event, name) => {
    console.log(event.target.value, name)
    if (allowedFields.includes(name)) {
      setVideo({...video, [name]: event.target.value});
    }
    //refetch()
  };

  const onRemoveTag = async tag => {
    await onRemoveTagsVideosData(client, tag.id);
    refetch();
  };

  const onAddTag = async () => {
    if (videoTags.filter(item => item.tag.id === tagId).length > 0)
      return;
    await onCreateTagsVideosData(client, tagId, video.id);
    refetch();
  };

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader>
        <PortletLabel
          title={video.title ? video.title : 'Title'}
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="status-simple">Status</InputLabel>
          <Select
            value={video.status ? video.status : ''}
            onChange={event => onChange(event, 'status')}
            onBlur={handleSubmit}
            inputProps={{
              name: 'status',
              id: 'status-simple',
            }}
          >
            <MenuItem value='inactive'>Inactive</MenuItem>
            <MenuItem value='active'>Active</MenuItem>
            <MenuItem value='pending'>Pending</MenuItem>
          </Select>
        </FormControl>
      </PortletHeader>
      <PortletContent>
        <form className={classes.form}>
          <TextField
            className={classes.textField}
            label="Title"
            name="title"
            onChange={event =>
              onChange(event, 'title')
            }
            onBlur={handleSubmit}
            type="text"
            value={video.title ? video.title : ''}
            variant="standard"
          />
          <TextField
            className={classes.textField}
            label="Description"
            name="description"
            onChange={event =>
              onChange(event, 'description')
            }
            onBlur={handleSubmit}
            type="text"
            value={video.description ? video.description : ''}
            variant="standard"
            multiline={true}
            rows="5"
          />
          {/* <TextField
              className={classes.textField}
              label="url"
              name="url"
              InputProps={{
                readOnly: true,
              }}
              type="text"
              value={video.url ? video.url:''}
              variant="standard"
            />
            <TextField
              className={classes.textField}
              label="thumbnail"
              name="thumbnail"
              InputProps={{
                readOnly: true,
              }}
              type="text"
              value={video.thumbnail ? video.thumbnail:''}
              variant="standard"
            /> */}
          <FormGroup row={true}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="status-simple">Category</InputLabel>
              <Select
                value={video.videoCategoryId ? video.videoCategoryId : (video.category ? video.category.id : '')}
                onChange={event => onChange(event, 'videoCategoryId')}
                onBlur={handleSubmit}
                inputProps={{
                  name: 'videoCategoryId',
                  id: 'category-simple',
                }}
              >
                {data && data.listCategorys && (
                  data.listCategorys.items.map((cat) => {

            
 
                  })
                )}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="status-simple">Show</InputLabel>
              {shows.length > 0 && (
                <Select
                  value={video.videoShowId ? video.videoShowId : (video.show ? video.show.id : '')}
                  onChange={event => onChange(event, 'videoShowId')}
                  onBlur={handleSubmit}
                  inputProps={{
                    name: 'videoShowId',
                    id: 'show-simple',
                  }}
                >
                  {
                    shows.map((show) => {
                      return <MenuItem key={show.id} value={show.id}>{show.title}</MenuItem>
                    })
                  }
                </Select>
              )}
            </FormControl>
            <FormControl className={classes.formControlSm}>
              <TextField
                label="S"
                name="season"
                onBlur={handleSubmit}
                onChange={event =>
                  onChange(event, 'season')
                }
                type="text"
                value={video.season ? video.season : ''}
                variant="standard"
              />
            </FormControl>
            <FormControl className={classes.formControlSm}>
              <TextField
                label="Ep."
                name="episode"
                onBlur={handleSubmit}
                onChange={event =>
                  onChange(event, 'episode')
                }
                type="text"
                value={video.episode ? video.episode : ''}
                variant="standard"
              />
            </FormControl>
            <FormControl className={classes.formControlSm}>
              <TextField
                label="Duration"
                name="duration"
                onBlur={handleSubmit}
                onChange={event =>
                  onChange(event, 'duration')
                }
                type="text"
                value={video.duration ? video.duration : ''}
                variant="standard"
              />
            </FormControl>
          </FormGroup>
        </form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {tags && <Select value={tagId} onChange={e => setTagId(e.target.value)} fullWidth>
              {tags.map(tag => <MenuItem key={tag.id} value={tag.id}>{tag.tag}</MenuItem>)}
            </Select>}
            <Button disabled={tagId === ''} onClick={onAddTag}>Add Tag</Button>
          </Grid>
          {videoTags.length > 0 && <Grid item xs={12}>
            {videoTags.map(t=> <Chip
                key={t.id}
                label={t.tag.tag}
                onDelete={() => onRemoveTag(t)}
                color="secondary"
              />)}
          </Grid>}
        </Grid>
      </PortletContent>
      {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </PortletFooter> */}
    </Portlet>
  );
}

VideoDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(VideoDetails);
