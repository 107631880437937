import React from 'react';
import { Grid } from '@material-ui/core';

//Screens:
import PublicProfile from './PublicProfile';


const DesignerPortal = () => {
  return (
        <Grid container>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <PublicProfile />
          </Grid>
        </Grid>
  );
};

export default DesignerPortal;
