import React, { useState, useEffect } from "react";
import {
  withStyles,
  Grid,
  LinearProgress,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell
} from "@material-ui/core";
import { VideoDetails, VideoPlayer } from "./components";
import uuid from "uuid";
import update from "immutability-helper";

import ProductLibrary from "components/ProductLibrary";
import VideoProducts from "./components/VideoProducts";
import VideoCast from "./components/VideoCast/VideoCast";
import { Portlet } from "components";
//graphql stuff
import gql from "graphql-tag";
import { getVideo, getProduct } from "graphql/queries";
import { useQuery } from "react-apollo-hooks";
import {
  createProductVideo,
  createProduct,
} from "graphql/mutations";

import { API, graphqlOperation } from "aws-amplify";

import styles from "./styles";

const getVideoQuery = gql`
  ${getVideo}
`;

const VideoDetail = props => {
  const { classes } = props;
  const { loading, refetch, data } = useQuery(getVideoQuery, {
    variables: { id: props.match.params.id }
  });
  const [video, setVideo] = useState(null);

  useEffect(() => {
    if (data.getVideo) setVideo(data.getVideo);
  }, [data]);

  const goBack = () => {
    props.history.goBack();
  };

  const createPV = async product => {
    let productVideoDetails = {
      id: uuid(),
      productVideoProductId: product.id,
      productVideoVideoId: video.id,
      timestamp: 1
    };
    let productVideo = await API.graphql(
      graphqlOperation(createProductVideo, { input: productVideoDetails })
    );
    refetch();
    console.log(productVideo);
  };

  const onProductClick = async item => {
    let product = await API.graphql(
      graphqlOperation(getProduct, { id: item.id })
    );
    if (!product.data.getProduct) {
      let productDetails = {
        id: item.id,
        name: item.name
      };
      let product = await API.graphql(
        graphqlOperation(createProduct, { input: productDetails })
      );
      createPV(product.data.createProduct);
    } else {
      createPV(product.data.getProduct);
    }
  };

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={goBack}
      >
        Go Back
      </Button>
      {loading || video === null ? (
        <div className={classes.progressWrapper}>
          <LinearProgress />
        </div>
      ) : (
        <Grid container spacing={4} className={classes.content}>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <VideoDetails refetch={refetch} video={video} />
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <VideoPlayer video={video} />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <VideoCast video={video} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Portlet>
              <ProductLibrary onClickItem={onProductClick} />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>UUID</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Timestamp (hh:mm:ss)</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <VideoProducts video={video} classes={classes} setVideo={setVideo} refetch={refetch} products={video.products} />
              </Table>
            </Portlet>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withStyles(styles)(VideoDetail);
