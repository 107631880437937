import React, {useRef, useState} from 'react';
import {
  Grid,
  Paper,
  Typography,
  Chip,
  withStyles,
  LinearProgress,
  Snackbar,
  Dialog,
  DialogTitle, TextField
} from "@material-ui/core";
import styles from "../../views/Videos/styles";
import TagsToolbar from "./TagsToolbar";
import NewTagModal from './NewTagModal';
import {onDeleteTag, onDeleteTagDesigner, onDeleteTagVideo} from "../../hooks/useTags";
import {useApolloClient} from "react-apollo-hooks";
import DialogPopup from "../DialogPopup";

const Tags = ({tags, refetch, onClick, loading, classes, type='Video'}) => {
  const [state, setState] = useState({newTagOpen: false});
  const [snackbar, setSnackbar] = useState({open: false, message: ''});
  const [deleteTag, setDeleteTag] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [dialog, setDialog] = useState({open: false, loading: false});
  const client = useApolloClient();

  const onNewTag = () => {
    setState({...state, newTagOpen: true})
  };

  const onCloseNewTag = (data) => {
    if (data.createTag) {
      setSnackbar({open: true, message: 'Created Tag'});
      refetch();
    }
    setState({...state, newTagOpen: false})
  };

  const onConfirmDelete = async (tag) => {
    setInputValue('');
    setDeleteTag(tag);
    setDialog({open: true})
  };

  const onDelete = async () => {
    setDialog({open: true, loading: true});
    try {
      for (let tagVideo of deleteTag.videos.items) {
        await onDeleteTagVideo(client, tagVideo.id);
      }

      for (let tagDesigner of deleteTag.designers.items) {
        await onDeleteTagDesigner(client, tagDesigner.id);
      }

      await onDeleteTag(client, deleteTag.id);
      await refetch();
      setSnackbar({open: true, message: 'Deleted!'})
    } catch (e) {
      setSnackbar({open: true, message: 'Something went wrong'});
      console.log(e);
    }
    setDialog({open: false});
    setDeleteTag(null);
  };

  const onCloseDialog = () => {
    setDialog({open: false});
    setDeleteTag(null);
  };

  const onSelectTag = async (tag) => {
    onClick(tag);
  };

  return (<Grid container spacing={3}>
    <Grid item xs={12}>
      <TagsToolbar refetch={refetch} onNewTag={onNewTag}/>
    </Grid>
    <Grid item xs={12}>
      <Paper className={classes.root}>
        {tags && tags.length === 0 && <Typography variant={'caption'}>No tags created</Typography>}
        {tags && tags.length > 0 && <>
          {tags.map(tag => (<Chip
            key={tag.id}
            label={tag.tag}
            onClick={() => onSelectTag(tag)}
            onDelete={() => onConfirmDelete(tag)}
            color="secondary"
          />))}
        </>}
        {(!tags || !!loading) && <LinearProgress />}
      </Paper>
    </Grid>
    {state.newTagOpen && <NewTagModal open={state.newTagOpen} type={type} onClose={onCloseNewTag} />}
    <Snackbar open={snackbar.open} message={snackbar.message} onClose={() => setSnackbar({open: false, message: ''})} autoHideDuration={3000}/>
    {deleteTag && <DialogPopup open={dialog.open} loading={dialog.loading} buttonText={'Delete'} disabled={inputValue !== deleteTag.tag} title={'Delete Tag'} onClose={onCloseDialog} onSubmit={onDelete}>
      <>
        <TextField value={inputValue} onChange={e => setInputValue(e.target.value)} placeholder={'Retype tag name'} />
        </>
    </DialogPopup>}
  </Grid>)
};

export default withStyles(styles)(Tags);
