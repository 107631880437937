export default theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    paddingBottom: "5px"
  },
  textField: {
    width: 100,
    height: 50,
    marginRight: 10,
  }
});
