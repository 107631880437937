import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  Snackbar,
  withStyles,
  DialogActions
} from "@material-ui/core";
import {
  IconButton,
  LinearProgress,
  Grid,
  Typography,
  Button
} from "@material-ui/core";

import XLSX from "xlsx";

// Material icons
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from "@material-ui/icons";

// graphql stuff

import { useApolloClient, useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { listProducts } from "../../graphql/queries";

// Shared services
import {
  deleteStripeProduct,
} from "../../services/stripe";
import { ProductsToolbar, ProductCard } from "./components";
import { filterItemsByName } from "../../services";

// Component styles
import styles from "./styles";
import ProductCategory from "./ProductCategory";
import NewProductCategoryModal from "./ProductCategory/NewProductCategoryModal";
import NewProductDialog from "./components/NewProductDialog";
import {
  deleteProduct,
  deleteProductCategory,
  deleteProductVideo,
  updateProduct
} from "../../graphql/mutations";
import { removeFile } from "../../services/storage";
import EditProductModal from "./components/EditProductModal";
import ProductCategoryDetailModal from "./ProductCategory/ProductCategoryDetail";
import ImportSpreadSheetModal from "./components/ImportSpreadSheetModal";
import useProductCategories from "../../hooks/useProductCategories";

const ProductList = ({ classes, history, ...props }) => {
  const [importedWB, setWorkbook] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [total, setTotal] = useState(0);
  const storageKey = 'hdtv_productListPage';

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editCategory, setEditCategory] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [search, setSearch] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    loading: false,
    actions: {}
  });
  const client = useApolloClient();

  const [state, setState] = useState({
    isLoading: false,
    limit: 6,
    products: [],
    productsTotal: 0,
    error: null,
    isNewProductOpen: false,
    isNewCategoryOpen: false
  });

  const {categories, refetch, error} = useProductCategories();
  const {mainCategories, } = useProductCategories({isMainCategory: {eq: true}});

  const {
    data: dataProducts,
    refetch: refetchProducts,
    loading: loadingProducts
  } = useQuery(gql(listProducts), {
    fetchPolicy: "network-only",
    variables: { limit: 1000,
      // filter: { active: { eq: true } }
    }
  });

  useEffect(() => {
    if (dataProducts.listProducts) {
      setState(s => ({ ...s, products: dataProducts.listProducts.items }));
      setTotal(dataProducts.listProducts.items.length);
      setPage(window.localStorage.getItem(storageKey) ? Number(window.localStorage.getItem(storageKey)): 0);
    }
  }, [dataProducts]);

  useEffect(() => {
    const _total = state.products.filter(product => filterItemsByName(search, product)).length;
    if (search === '') {
      setPage(window.localStorage.getItem(storageKey) ? Number(window.localStorage.getItem(storageKey)): 0);
      setTotal(state.products.length);
    } else if (page * rowsPerPage > _total) {
      setPage(0);
      setTotal(_total);
    } else {
      setTotal(_total);
    }
  }, [search, state.products]);

  const setLoading = () =>
    setDialog({
      open: true,
      title: "",
      content: "",
      loading: true,
      actions: {}
    });

  const closeLoading = () =>
    setDialog({
      open: false,
      title: "",
      content: "",
      loading: false,
      actions: {}
    });

  const onSearch = event => {
    setSearch(event.target.value);
  };

  const onNewProductClose = () => {
    refetchProducts();
    setState({ ...state, isNewProductOpen: false });
  };
  const onNewProductOpen = () => setState({ ...state, isNewProductOpen: true });

  const onNewCategoryClose = () => {
    refetch();
    setEditCategory(null);
    setState({ ...state, isNewCategoryOpen: false });
  };

  const onNewCategoryOpen = () =>
    setState({ ...state, isNewCategoryOpen: true });

  const onEditCategory = category => {
    setEditCategory(category);
    setState({ ...state, isNewCategoryOpen: true });
  };

  const onEditProduct = product => {
    setEditProduct(product);
  };

  const onAddProductToCategory = async (product, category_id) => {
    const data = await client.mutate({
      mutation: gql(updateProduct),
      variables: { input: { id: product.id, productCategoryId: category_id } }
    });
    refetchProducts();
    refetch();
    setSnackbar({ open: true, message: "Saved" });
  };

  const onRemoveProductFromCategory = async product => {
    const data = await client.mutate({
      mutation: gql(updateProduct),
      variables: { input: { id: product.id, productCategoryId: null } }
    });
    console.log("updated product", data);
    refetchProducts();
    refetch();
    setSnackbar({ open: true, message: "Removed" });
  };

  const onDeleteCategory = async category => {
    async function ok() {
      setLoading();
      await removeFile(category.image);
      await client.mutate({
        mutation: gql(deleteProductCategory),
        variables: { input: { id: category.id } }
      });
      await refetch();
      closeLoading();
    }

    setDialog({
      open: true,
      content: "Removing product category? This action is undoable",
      actions: { ok, cancel: closeDialog }
    });
  };

  const onDeleteProduct = async product => {
    async function ok() {
      setDialog({ ...dialog, loading: true });

      await removeFile(product.image);

      if (product.product_id) {
        const res = await deleteStripeProduct(product.product_id);

        console.log("removed stripe product: ", res);
      }

      for (let _p of product.videos.items) {
        await client.mutate({
          mutation: gql(deleteProductVideo),
          variables: { input: { id: _p.id } }
        });
      }

      const res = await client.mutate({
        mutation: gql(deleteProduct),
        variables: { input: { id: product.id } }
      });

      console.log("delete product: ", res);

      await refetchProducts();
      // await client.mutate({
      //   mutation: gql(updateProduct),
      //   variables: {input: {id: product.id, active: false}}
      // });
      closeDialog();
    }

    setDialog({
      open: true,
      content: "Removing product? This action is not undoable!",
      actions: { ok, cancel: closeDialog }
    });
  };

  const onImportSpreadSheet = (file) => {
    try {
      if (file) {
        setLoading();
        const reader = new FileReader();
        reader.onload = evt => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          setWorkbook(wb);
          closeLoading();
        };

        reader.readAsBinaryString(file);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closeDialog = () =>
    setDialog({ open: false, content: "", actions: {} });

  const onRefetch = async () => {
    refetch();
    refetchProducts();
  };

  const renderProducts = () => {
    if (loadingProducts) {
      return (
        <div className={classes.progressWrapper}>
          <LinearProgress />
        </div>
      );
    }

    if (state.products.length === 0) {
      return (
        <Typography variant="h6">There are no products available</Typography>
      );
    }

    return (
      <Grid container spacing={3}>
        {state.products
          .filter(product => filterItemsByName(search, product))
          .filter((_, index) => {
            return index >= page * rowsPerPage && index < (page + 1) * rowsPerPage
          })
          .map(product => (
            <Grid item key={product.id} lg={4} md={6} xs={12}>
              <Link to="#">
                <ProductCard
                  onEditProduct={onEditProduct}
                  onDeleteProduct={onDeleteProduct}
                  product={product}
                />
              </Link>
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.root}>
          <ProductCategory
            onShowCategory={cat => setSelectedCategory(cat)}
            onEditCategory={onEditCategory}
            onRefetch={onRefetch}
            onDeleteCategory={onDeleteCategory}
            onNewCategory={onNewCategoryOpen}
            refetch={refetch}
            onClose={onNewCategoryClose}
            categories={categories}
            mainCategories={mainCategories}
            error={error}
          />
          <NewProductCategoryModal
            open={state.isNewCategoryOpen}
            category={editCategory}
            categories={categories}
            onClose={onNewCategoryClose}
          />
          {selectedCategory && (
            <ProductCategoryDetailModal
              onRemove={onRemoveProductFromCategory}
              open={!!selectedCategory}
              category={selectedCategory}
              onClose={() => setSelectedCategory(null)}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.root}>
          <ProductsToolbar
            refetch={() => refetchProducts()}
            onAddClick={onNewProductOpen}
            onImportSpreadSheet={onImportSpreadSheet}
            onSearch={onSearch}
            navigation={history}
            onNewProduct={onNewProductOpen}
          />
          <div className={classes.content}>{renderProducts()}</div>
          <div className={classes.pagination}>
            <Typography variant="caption">{page * rowsPerPage + 1}-{(page + 1)*rowsPerPage < total? (page + 1)*rowsPerPage: total} of {total}</Typography>
            <IconButton disabled={page === 0} onClick={() => {
              if (page > 0) {
                window.localStorage.setItem(storageKey, (page -1).toString());
                setPage(page -1);
              }
            }}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton disabled={(page + 1)*rowsPerPage >= total} onClick={() => {
              if ((page + 1)*rowsPerPage < total) {
                window.localStorage.setItem(storageKey, (page +1).toString());
                setPage(page + 1);
              }
            }}>
              <ChevronRightIcon />
            </IconButton>
            <Typography variant={'caption'}>
              Page: {page + 1}/{Math.ceil(total/rowsPerPage)}
            </Typography>
          </div>
        </div>
        <NewProductDialog
          open={state.isNewProductOpen}
          onClose={onNewProductClose}
        />
        <ImportSpreadSheetModal
          workbook={importedWB}
          onClose={() => setWorkbook(null)}
          categories={categories}
          refetch={() => refetchProducts()}
        />
        {editProduct && (
          <EditProductModal
            onAddProductToCategory={onAddProductToCategory}
            categories={categories}
            open={!!editProduct}
            product={editProduct}
            onClose={() => setEditProduct(null)}
          />
        )}
      </Grid>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ open: false, message: "" })}
      />
      <Dialog open={dialog.open}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialog.loading ? "Processing..." : dialog.content}
          </DialogContentText>
          {dialog.loading && <LinearProgress />}
        </DialogContent>
        {!dialog.loading && dialog.actions && (
          <DialogActions>
            <Button onClick={dialog.actions.ok}>Ok</Button>
            <Button onClick={dialog.actions.cancel}>Cancel</Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
};

ProductList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductList);
