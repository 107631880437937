export default theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  fileInput: {
    width: .1,
    height: '100%',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
    '& +label': {
      padding: '3px 9px',
      borderRadius: 4,
      lineHeight: '1.75',
      textTransform: 'uppercase',
      border: '1px solid rgba(163, 51, 200, .5)',
      boxSizing: 'border-box',
      fontSize: '.8125rem',
      fontWeight: '700',
      color: '#a333c8',
      backgroundColor: 'white',
      display: 'inline-block',
      cursor: 'pointer',
    },
  }
});
