import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Button, TextField, FormControl, MenuItem, InputLabel, Select, Grid, Typography } from '@material-ui/core';

//graphql
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { updatePost } from 'graphql/mutations';
import { createPost } from 'graphql/mutations';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import PhotoLibrary from 'components/PhotoLibrary'

const UpdatePostQuery = gql`${updatePost}`
const CreatePostQuery = gql`${createPost}`

const quillOptions = {
  modules: {
    clipboard: {},
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],        // custom button values
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],
      ['blockquote','link', 'image', 'code-block', 'video']

    ]
  },
  placeholder: 'Compose an epic...'
}

const PostDetails = (props) => {
    const [ post, setPost ] = useState({...props.post})
    const { classes, className } = props;
    const rootClassName = classNames(classes.root, className);
    const [ create, setCreate ] = useState(false)
    const [ disabled, setDisabled ] = useState(false)

    const updatePost = useMutation(UpdatePostQuery);
    const createPost = useMutation(CreatePostQuery);

    const allowedFields = ['id', 'title', 'description', 'content', 'status', 'thumbnail'];
    const handleSubmit = async () => {
      setDisabled(true)
      const input = Object.keys(post).reduce((object, key) => {
        if (allowedFields.includes(key) && post[key] !== null) {
          object[key] = post[key];
        }
        return object;
      }, {});
      const payload = {
        variables: {
          input: input
        }
      };
      await ((create) ? createPost(payload):updatePost(payload));
      props.history.goBack()
    }

    useEffect(() => {
      console.log(props.post)
      if(props.match.params.id === 'new'){
        setCreate(true)
      }
    },[props.match.params.id, props.post])

    const onEditorChange = (value) => {
      setPost({ ...post, content: value });
    }

    const onChange = (event, name) => {
      console.log(event.target.value, name)
      if(allowedFields.includes(name)){
        setPost({ ...post, [name]: event.target.value });
        console.log(disabled)
      }
    };

    const onPhotoClick = (item) => {
      console.log(item)
      setPost({ ...post, thumbnail: 'https://hdtv-prod.s3.amazonaws.com/public/' + item.key})
    }

    return (
      <Portlet
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle="Post Information"
            title={post.title ? post.title:'Title'}
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="status-simple">Status</InputLabel>
            <Select
              value={post.status ? post.status:''}
              onChange={event => onChange(event, 'status')}
              onBlur={handleSubmit}
              inputProps={{
                name: 'status',
                id: 'status-simple',
              }}
            >
              <MenuItem value='draft'>Draft</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>
              <MenuItem value='published'>Published</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
            </Select>
          </FormControl>
        </PortletHeader>
        <PortletContent>
          <form className={classes.form}>
            <TextField
              className={classes.textField}
              label="Title"
              name="title"
              onChange={event =>
                onChange(event, 'title')
              }
              type="text"
              value={post.title ? post.title:''}
              variant="standard"
            />
            <TextField
              className={classes.textField}
              label="Description"
              name="description"
              onChange={event =>
                onChange(event, 'description')
              }
              type="text"
              value={post.description ? post.description:''}
              variant="standard"
              multiline={true}
              rows="5"
            />
            <Grid container>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                {post.thumbnail && (
                  <img src={post.thumbnail} width='100%' alt={post.title}/>
                )}
                <PhotoLibrary onClickItem={onPhotoClick}/>
              </Grid>
              <Grid item xs={12} sm={10} md={10} lg={10}>
                <TextField
                  className={classes.textField}
                  label="Thumbnail URL"
                  name="thumbnail"
                  onChange={event =>
                    onChange(event, 'thumbnail')
                  }
                  type="text"
                  value={post.thumbnail ? post.thumbnail:''}
                  variant="standard"
                  required
                />
              </Grid>

            </Grid>
            <div style={{height:'600px'}}>
              <ReactQuill value={post.content ? post.content:''}
                  onChange={onEditorChange} style={{height:'90%'}} {...quillOptions}/>
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSubmit}
            disabled={(disabled || !post.thumbnail)}
          >
            Save
          </Button>
         {!post.thumbnail && (<Typography style={{color:'red'}}>Please fill out info and upload a thumbnail</Typography>)}
        </PortletFooter>
      </Portlet>
    );
}

PostDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PostDetails);
