import React, {useState, useEffect} from 'react';
import moment from 'moment-timezone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Paper, withStyles} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {
  LinearProgress,
  Typography,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Link,
  Chip,
  Grid,
  Button
} from '@material-ui/core';

import {VideosToolbar} from './components';
import {Portlet, PortletContent} from 'components';
import PendingVideos from './pending-videos';
import styles from './styles';

//graphql
import useTags from "../../hooks/useTags";
import Tags from "../../components/Tags";
import useVideos from "../../hooks/useVideos";

const VideoList = props => {
  const {classes} = props;
  const [limit] = useState(10000);
  const [selectedTags, setSlectedTags] = useState([]);
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const {tags, refetch: refetchTags} = useTags();
  const [status, setStatus] = useState('all');

  const statuses = ['All', 'Active', 'Inactive'];

  const VideoLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

  const {data, loading, refetch} = useVideos('network-only', limit);

  useEffect(() => {
    if (
      data.listVideos !== null &&
      typeof data.listVideos !== 'undefined'
    ) {
      console.log(data.listVideos);
      setVideos(data.listVideos.items);
      //setLimit((data.listHdtvVideos.items.length > limit) ? limit:data.listHdtvVideos.items.length)
    }
  }, [data]);

  useEffect(() => {
    setPage(window.localStorage.getItem('videoListPage') && selectedTags.length === 0 ? window.localStorage.getItem('videoListPage') : 0)
  }, [selectedTags]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, videos.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    if (selectedTags.length === 0)
      window.localStorage.setItem('videoListPage', newPage);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onSearch = (event) => {
    setPage(0);
    setSearch(event.target.value)
  };

  const onFilterByStatus = stat => {
    setPage(0);
    setStatus(stat.toLowerCase());
  };

  function filterVideosByTags(video) {
    const tags = selectedTags.filter(tag => video.tags.items.filter(item => item.tag.id === tag.id).length > 0);
    return (selectedTags.length === 0) ? true : tags.length > 0
  }

  function filterVideos(q, stat, item) {
    if (stat !== 'all' && item.status !== stat) {
      return false;
    }

    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }

    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    return (searchRegex.test(item.id) || searchRegex.test(item.title) || searchRegex.test(item.description) || searchRegex.test(moment(item.createdAt.slice(0, 16) + '+0000')
      .tz('America/Chicago')
      .format('llll')));
  }

  const onSelectedTag = tag => {
    if (selectedTags.filter(t => t.id === tag.id).length === 0) {
      setSlectedTags([...selectedTags, tag])
    }
  };

  const onDeleteSelectedTag = tag => {
    setSlectedTags(selectedTags.filter(t => t.id !== tag.id))
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.root}>
          <Tags tags={tags} onClick={onSelectedTag} refetch={refetchTags}/>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.root}>
          <VideosToolbar refetch={refetch} statuses={statuses} filter={onFilterByStatus} search={onSearch}/>
          <div className={classes.content}>
            {loading && (
              <div className={classes.progressWrapper}>
                <LinearProgress/>
              </div>
            )}
            {videos.length === 0 && (
              <Typography variant="h6">There are no videos available</Typography>
            )}
            {videos.length > 0 && (
              <Portlet>
                <PortletContent noPadding>
                  <PerfectScrollbar>
                    {selectedTags.length > 0 && <Grid item xs={12}>
                      <Paper className={classes.root}>
                        <Button onClick={() => setSlectedTags([])}>Clear Tags</Button>
                        {selectedTags.map(t => (<Chip
                          key={t.id}
                          label={t.tag}
                          onDelete={() => onDeleteSelectedTag(t)}
                          color="secondary"
                        />))}
                      </Paper>
                    </Grid>}
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">GUID</TableCell>
                          <TableCell align="left">Title</TableCell>
                          <TableCell align="left">Desc</TableCell>
                          <TableCell align="left">Upload date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {videos
                          .filter(video => filterVideos(search, status, video))
                          .filter(video => filterVideosByTags(video))
                          //.filter(video => video.guid.toLowerCase().includes(search.toLowerCase()))
                          //.filter(video => ( video.id.toLowerCase().includes(search.toLowerCase()) || video.title.toLowerCase().includes(search.toLowerCase()) || video.description.toLowerCase().includes(search.toLowerCase())) )
                          .sort(function compare(a, b) {
                            // var dateA = new Date(a.startTime.slice(0, 16));
                            // var dateB = new Date(b.startTime.slice(0, 16));
                            var dateA = new Date(a.createdAt.slice(0, 16));
                            var dateB = new Date(b.createdAt.slice(0, 16));
                            return dateB - dateA;
                          })
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map(video => (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={video.id}>
                              <TableCell className={classes.tableCell}>
                                <Link component={VideoLink} to={'/videos/' + video.id}>
                                  {video.id}
                                </Link>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {video.title}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {video.description}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {
                                  //moment(video.startTime.slice(0, 16) + '+0000')
                                  moment(video.createdAt.slice(0, 16) + '+0000')
                                    .tz('America/Chicago')
                                    .format('llll')
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                        {emptyRows > 0 && (
                          <TableRow style={{height: 56 * emptyRows}}>
                            <TableCell colSpan={4}/>
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={4}
                            count={videos.length}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            page={parseInt(page)}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                            SelectProps={{
                              inputProps: {'aria-label': 'Rows per page'},
                              native: true
                            }}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </PerfectScrollbar>
                </PortletContent>
              </Portlet>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.root}>
          <PendingVideos classes={classes} videos={videos} filterVideos={filterVideos} refetch={refetch} loading={loading}/>
        </div>
      </Grid>

    </Grid>
  );
};

export default withStyles(styles)(VideoList);
