import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles, Button, TextField, Grid } from "@material-ui/core";
import ProgressBar from "components/ProgressBar";

//graphql
import { useMutation, useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import {
  updateDesigner,
  deleteDesigner,
  createDesigner
} from "graphql/mutations";
import uuid from "uuid";
import { Storage } from "aws-amplify";

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from "components";

// Component styles
import styles from "./styles";
import "react-quill/dist/quill.snow.css";

import { DesignerPortal } from "../../../../../../components/DesignerPortal";
import { getDesigner } from "../../../../../../graphql/queries";
import {updateDesignerData} from "../../../../../../hooks/useUpdateDesigner";

export const DesignerContext = React.createContext({});

const GetDesignerQuery = gql`
  ${getDesigner}
`;

const UpdateDesignerQuery = gql`
  ${updateDesigner}
`;
const DeleteDesignerQuery = gql`
  ${deleteDesigner}
`;
const CreateDesignerQuery = gql`
  ${createDesigner}
`;

const DesignerDetails = ({ staticContext, ...props }) => {
  const [designer, setDesigner] = useState(props.designer);
  const { data, refetch, error } = useQuery(GetDesignerQuery, {
    variables: { id: props.designer.id }
  });
  const { classes, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);
  const [create, setCreate] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [updatedPhoto, setUpdatedPhoto] = useState(false);

  const updateDesigner = useMutation(UpdateDesignerQuery);
  const deleteDesigner = useMutation(DeleteDesignerQuery);
  const createDesigner = useMutation(CreateDesignerQuery);

  React.useEffect(() => {
    if (data.getDesigner) {
      (async function() {
        if (!data.getDesigner.profile) {
          const payload = {};
          if (data.getDesigner.photo_url) {
            payload.photo_url = data.getDesigner.photo_url;
          }

          if (data.getDesigner.bio) {
            payload.bio = data.getDesigner.bio;
          }

          if (data.getDesigner.name) {
            payload.name = data.getDesigner.name;
          }
          await updateDesignerData(data.getDesigner.id, null, null, {
            profile: payload,
          });
          return refetch();
        }

        if (!data.getDesigner.draft_profile) {
          const payload = Object.assign({}, data.getDesigner.profile);
          delete payload.__typename;
          if (data.getDesigner.photo_url) {
            payload.photo_url = data.getDesigner.photo_url;
          }

          if (data.getDesigner.bio) {
            payload.bio = data.getDesigner.bio;
          }

          if (data.getDesigner.name) {
            payload.name = data.getDesigner.name;
          }

          await updateDesignerData(data.getDesigner.id, null, null, {
            draft_profile: payload
          });

          return refetch();
        }
      })();

      setDesigner(data.getDesigner);
    }
  }, [data]);

  const allowedFields = ["id", "name", "bio", "email", "phone", "photo_url"];

  const handleSubmit = async () => {
    setDisabled(true);
    const input = Object.keys(designer).reduce((object, key) => {
      if (
        allowedFields.includes(key) &&
        designer[key] !== null &&
        designer[key] !== ""
      ) {
        object[key] = designer[key];
      }
      return object;
    }, {});
    const profile = designer.profile? Object.assign({}, designer.profile): {};
    const draft_profile = designer.draft_profile? Object.assign({}, designer.draft_profile): {};
    delete draft_profile.__typename;
    if (Boolean(input.photo_url)) {
      profile.photo_url = input.photo_url;
      draft_profile.photo_url = input.photo_url;
    }

    if (Boolean(input.bio)) {
      profile.bio = input.bio;
      draft_profile.bio = input.bio;
    }

    if (Boolean(input.name)) {
      profile.name = input.name;
      draft_profile.name = input.name;
    }

    input.profile = profile;
    input.draft_profile = draft_profile;
    delete input.profile.__typename;
    delete input.draft_profile.__typename;

    const payload = {
      variables: {
        input: input
      }
    };
    await (create ? createDesigner(payload) : updateDesigner(payload));
    props.history.goBack();
  };

  useEffect(() => {
    if (props.match.params.id === "new") {
      setCreate(true);
    }
  }, [props.designer, props.match.params.id]);

  const onChange = (event, name) => {
    if (allowedFields.includes(name)) {
      setDesigner({ ...designer, [name]: event.target.value });
    }
  };

  const onChangeHandler = event => {
    setSelectedFile(event.target.files[0]);
    setUpdatedPhoto(true);
  };

  useEffect(() => {
    if (updatedPhoto) handleSubmit();
  }, [designer.photo_url]);

  const uploadFile = () => {
    setDisabled(true);
    let ext = selectedFile.name.split(".").pop();
    let key = uuid() + "." + ext;
    Storage.put("images/" + designer.id + "/" + key, selectedFile, {
      bucket: "hdtv-prod",
      level: "public",
      progressCallback(progress) {
        setLoaded(progress);
      }
    }).then(res => {
      removePhoto();
      setDisabled(false);
      setLoaded(null);
      setDesigner({ ...designer, photo_url: res.key });
    });
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure?")) {
      const payload = {
        variables: {
          input: {
            id: designer.id
          }
        }
      };
      deleteDesigner(payload);
    }
  };

  const removePhoto = () => {
    if (designer.photo_url) {
      Storage.remove(designer.photo_url, {
        bucket: "hdtv-prod",
        level: "public"
      })
        .then(() => true)
        .catch(err => console.log(err));
    } else {
      return true;
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item lg={4} md={6} xl={4} xs={12}>
        <Portlet {...rest} className={rootClassName}>
          <PortletContent style={{ padding: "0px", marginBottom: "-3px" }}>
            <img
              height="100%"
              width="100%"
              src={
                "https://hdtv-prod.s3.amazonaws.com/public/" +
                designer.photo_url
              }
              alt="profile"
            />
            <div className={classes.progressWrapper}>
              {loaded && (
                <div className={classes.row}>
                  <ProgressBar progress={loaded} />
                </div>
              )}
            </div>
          </PortletContent>
          <PortletFooter>
            <input
              color="primary"
              size="small"
              variant="outlined"
              type="file"
              name="file"
              onChange={onChangeHandler}
            />
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={uploadFile}
              disabled={!selectedFile}
            >
              Upload
            </Button>
          </PortletFooter>
        </Portlet>
      </Grid>
      <Grid item lg={8} md={6} xl={8} xs={12}>
        <Portlet {...rest} className={rootClassName}>
          <PortletHeader>
            <PortletLabel
              subtitle="The information can be edited"
              title="Profile"
            />
            <Button
              variant="contained"
              onClick={() => handleDelete()}
              style={{ float: "right", backgroundColor: "red", color: "white" }}
            >
              Delete User
            </Button>
          </PortletHeader>
          <PortletContent noPadding>
            <form autoComplete="off">
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  label="Name"
                  margin="dense"
                  onChange={event => onChange(event, "name")}
                  required
                  value={designer.name ? designer.name : ""}
                  variant="outlined"
                />
              </div>
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  label="Email Address"
                  margin="dense"
                  onChange={event => onChange(event, "email")}
                  required
                  value={designer.email ? designer.email : ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label="Phone Number"
                  margin="dense"
                  onChange={event => onChange(event, "phone")}
                  value={designer.phone ? designer.phone : ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  label="Photo URL"
                  margin="dense"
                  onChange={event => onChange(event, "photo_url")}
                  value={designer.photo_url ? designer.photo_url : ""}
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                />
                <TextField
                  className={classes.textField}
                  label="Bio"
                  margin="dense"
                  onChange={event => onChange(event, "bio")}
                  required
                  value={designer.bio ? designer.bio : ""}
                  variant="outlined"
                  multiline
                  rows="4"
                />
              </div>
            </form>
          </PortletContent>
          <PortletFooter className={classes.portletFooter}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={disabled}
            >
              Save details
            </Button>
          </PortletFooter>
        </Portlet>
      </Grid>
      <Grid item xs={12}>
        <DesignerContext.Provider
          value={{ designer: designer, refetch: refetch }}
        >
          <DesignerPortal />
        </DesignerContext.Provider>
      </Grid>
    </Grid>
  );
};

DesignerDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DesignerDetails);
