export default theme => ({
    root: {},
    form: {},
    textField: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    portletFooter: {},
    formControl: {
      width: '40%'
    },
    formControlSm: {
      width: '10%'
    },
    formGroup: {
      display: 'flex'
    }
  });
  