import React, { useEffect, useRef, useState } from 'react';
import { Button, FormControl, Grid, TextField, InputLabel, Typography } from '@material-ui/core';

const NameForm = ({ classes, onSubmit, designer, setSnackbar }) => {
  const [state, setState] = useState(null);
  const firstNameField = useRef();
  const lastNameField = useRef();
  const nameField = useRef();

  useEffect(() => {
    if (designer.id) {
      setState({
        first_name: designer.draft_profile && designer.draft_profile.first_name ? designer.draft_profile.first_name : '',
        last_name: designer.draft_profile && designer.draft_profile.last_name ? designer.draft_profile.last_name : '',
        name: designer.draft_profile && designer.draft_profile.name ? designer.draft_profile.name : ''
      });
    }
  }, [designer]);

  const onSubmitData = () => {
    if (state.first_name.length === 0) {
      firstNameField.current.focus();
      return setSnackbar({ open: true, message: 'First Name is required' });
    }

    if (state.last_name.length === 0) {
      lastNameField.current.focus();
      return setSnackbar({ open: true, message: 'Last Name is required' });
    }

    if (nameField.current.value === '') {
      nameField.current.focus();
      return setSnackbar({ open: true, message: 'Business Name is required' });
    }

    onSubmit({ ...state, name: nameField.current.value });
  };

  return state ? (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.centered}>
        <Typography variant={'h2'} color={'textPrimary'} component={'h2'}>
          Public Profile
        </Typography>
        <Typography color={'textSecondary'}>This information is displayed at the top of your profile</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>First Name</InputLabel>
          <TextField
            inputRef={firstNameField}
            id="first_name"
            margin="normal"
            variant="outlined"
            inputProps={{ 'aria-label': 'bare' }}
            value={state.first_name}
            onChange={e => setState({ ...state, first_name: e.target.value })}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Last Name</InputLabel>
          <TextField
            id="last_name"
            inputRef={lastNameField}
            className={classes.textField}
            margin="normal"
            variant={'outlined'}
            value={state.last_name}
            onChange={e => setState({ ...state, last_name: e.target.value })}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Business Name</InputLabel>
          <TextField id="name" inputRef={nameField} className={classes.textField} margin="normal" variant={'outlined'} defaultValue={state.name} />
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.centered}>
        <Button variant={'contained'} color={'primary'} onClick={onSubmitData}>
          Save Changes
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Typography>Loading....</Typography>
  );
};

export default NameForm;
