import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {IconButton, withStyles, Link} from '@material-ui/core';
import {Typography, Divider} from '@material-ui/core';
import {DeleteOutlined, EditOutlined} from "@material-ui/icons";

// Shared components
import {Paper} from 'components';

// Component styles
import styles from './styles';

class ProductCategoryCard extends Component {
  render() {
    const {classes, className, category, onDelete, refetch, onEditCategory, onCategoryDetail} = this.props;

    const rootClassName = classNames(classes.root, className);

    const onDeleteCategory = async () => {
      await onDelete(category);
      await refetch();
    };

    return (
      <Paper className={rootClassName}>
        <Link to={'#'} onClick={onCategoryDetail}>
          <div className={classes.imageWrapper}>
            {category.image ? <img
              alt="Product"
              className={classes.image}
              src={`https://hdtv-prod.s3.amazonaws.com/public/${category.image}`}
            /> : <Typography variant={'caption'}>No image</Typography>}
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h4"
            >
              {category.name}
            </Typography>
            <Typography
              className={classes.description}
              variant="body1"
            >
              {category.slug}
            </Typography>
          </div>
          <Divider/>
        </Link>
        <div className={classes.stats}>
          <IconButton onClick={onDeleteCategory}><DeleteOutlined/></IconButton>
          <IconButton onClick={() => onEditCategory(category)}><EditOutlined/></IconButton>
        </div>
      </Paper>
    );
  }
}

ProductCategoryCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCategoryCard);
