import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, IconButton, Button } from '@material-ui/core';
import { SearchInput } from 'components';
import styles from './styles';
import uuid from 'uuid'
import { Storage } from 'aws-amplify'
import ProgressBar from 'components/ProgressBar'
import { Refresh as RefreshIcon } from '@material-ui/icons'


const VideosToolbar = (props) => {

  const { classes, className, search, filter, statuses, noUpload } = props
  const [ selectedFile, setSelectedFile ] = useState(null)
  const [ loaded, setLoaded ] = useState(null)
  const [ disabled, setDisabled ] = useState(false)
  const [status, setStatus] = useState(statuses[0]);

  const onChangeHandler=event=>{
    setSelectedFile(event.target.files[0])
  }

  const refresh = () => {
    props.refetch()
  }

  const onFilter = stat => () => {
    setStatus(stat);
    filter(stat);
  };

  const uploadFile = () => {
    setDisabled(true)
    let ext = selectedFile.name.split('.').pop();
    let key = uuid()+'.'+ext;
    Storage.put('videos/'+key, selectedFile, {
      bucket: 'hdtv-videos-source-2osorqelt13t',
      level: 'public',
      progressCallback(progress) {
        setLoaded(progress)
      },
    }).then(() => {
      setDisabled(false)
      setLoaded(null)
    });

  }

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {loaded &&
        <div className={classes.row}>
          <ProgressBar progress={loaded} />
        </div>
      }
      {!noUpload && <div className={classes.row}>
        <span className={classes.spacer} />
        <input color="primary" size="small" variant="outlined" type="file" name="file" onChange={onChangeHandler}/>
        <Button color="primary" size="small" variant="outlined" onClick={uploadFile} disabled={!selectedFile || disabled}>
          Upload
        </Button>
      </div>}
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search video"
          onChange={search}
        />
        <span className={classes.spacer} />
        {statuses.map(stat => <Button key={stat} disabled={stat===status} onClick={onFilter(stat)}>{stat}</Button>)}
        <IconButton aria-label="Add" onClick={refresh}>
          <RefreshIcon color="primary" />
        </IconButton>
        {/* <DisplayMode mode="list" /> */}
      </div>
    </div>
  );
}

VideosToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(VideosToolbar);
