import React from 'react';

import { Grid, Avatar, withStyles, LinearProgress, Typography, Chip } from '@material-ui/core';
import styles from './styles';

const DesignerProfile = ({ classes, designer = {}}) => {
  const first_name =
    designer.draft_profile && designer.draft_profile.first_name && designer.draft_profile.first_name !== ' ' ? designer.draft_profile.first_name : designer.first_name;
  const last_name = designer.draft_profile && designer.draft_profile.last_name && designer.draft_profile.last_name !== ' ' ? designer.draft_profile.last_name : designer.last_name;
  const photo_url = designer.draft_profile && designer.draft_profile.photo_url && designer.draft_profile.photo_url !== ' ' ? designer.draft_profile.photo_url : designer.photo_url;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={3} justify={'space-between'} alignItems={'center'}>
        {!designer.id && <LinearProgress className={classes.fullWidth} />}
      </Grid>
      {designer.draft_profile && (
        <Grid item xs={12} container>
          {/*<Grid item xs={12} container className={classes.header}>*/}
          {/*  <Grid item xs={12} className={classes.imageContainer}>*/}
          {/*    <img*/}
          {/*      alt={`${first_name} ${last_name}`}*/}
          {/*      src={*/}
          {/*        designer.draft_profile.cover_url*/}
          {/*          ? `https://hdtv-prod.s3.amazonaws.com/public/${designer.draft_profile.cover_url}`*/}
          {/*          : 'https://images.unsplash.com/photo-1536566482680-fca31930a0bd'*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={3}>
              <Avatar
                className={classes.avatar}
                src={photo_url ? `https://hdtv-prod.s3.amazonaws.com/public/${photo_url}` : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
              />
            </Grid>
            <Grid item xs={9} container>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Name: </Typography>
                <Typography variant={'caption'}>{`${first_name} ${last_name}`}</Typography>
                <Typography>
                  Business name: <span className={classes.caption}>{designer.draft_profile.name}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Contact:</Typography>
                <Typography>
                  Company name: <span className={classes.caption}>{designer.draft_profile.company_name}</span>
                </Typography>
                <Typography>
                  Your title: <span className={classes.caption}>{designer.draft_profile.title}</span>
                </Typography>
                <Typography>
                  Street address: <span className={classes.caption}>{designer.draft_profile.address_line1}</span>
                </Typography>
                <Typography>
                  Street address 2: <span className={classes.caption}>{designer.draft_profile.address_line2}</span>
                </Typography>
                <Typography>
                  City: <span className={classes.caption}>{designer.draft_profile.city}</span>
                </Typography>
                <Typography>
                  State: <span className={classes.caption}>{designer.draft_profile.state}</span>
                </Typography>
                <Typography>
                  Zip: <span className={classes.caption}>{designer.draft_profile.zip_code}</span>
                </Typography>
                <Typography>
                  Country: <span className={classes.caption}>{designer.draft_profile.country}</span>
                </Typography>
                <Typography>
                  Phone: <span className={classes.caption}>{designer.phone}</span>
                </Typography>
                <Typography>
                  Email: <span className={classes.caption}>{designer.email}</span>
                </Typography>
                <Typography>
                  Your website url: <span className={classes.caption}>{designer.draft_profile.website_url}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Social Media:</Typography>
                <Typography>
                  Facebook Handle: <span className={classes.caption}>{designer.draft_profile.facebook_handle}</span>
                </Typography>
                <Typography>
                  Twitter Handle: <span className={classes.caption}>{designer.draft_profile.twitter_handle}</span>
                </Typography>
                <Typography>
                  Youtube Handle: <span className={classes.caption}>{designer.draft_profile.youtube_handle}</span>
                </Typography>
                <Typography>
                  Pinterest Handle: <span className={classes.caption}>{designer.draft_profile.pinterest_handle}</span>
                </Typography>
                <Typography>
                  Instagram Handle: <span className={classes.caption}>{designer.draft_profile.instagram_handle}</span>
                </Typography>
                <Typography>
                  Linkedin URL: <span className={classes.caption}>{designer.draft_profile.linkedin_url}</span>
                </Typography>
                <Typography>
                  Blog URL: <span className={classes.caption}>{designer.draft_profile.blog_url}</span>
                </Typography>
                <Typography>
                  Google+ URL: <span className={classes.caption}>{designer.draft_profile.google_plus_url}</span>
                </Typography>
                <Typography>
                  Houzz URL: <span className={classes.caption}>{designer.draft_profile.houzz_url}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Biographical Information:</Typography>
                <Typography>
                  Bio: <span className={classes.caption}>{designer.draft_profile.bio}</span>
                </Typography>
                <Typography>Available for:</Typography>
                <span className={classes.caption}>{designer.draft_profile.availables && designer.draft_profile.availables.map(item => <Chip key={item} label={item} />)} </span>
                <Typography>My Certifications and Memberships:</Typography>
                {designer.draft_profile.certifications && designer.draft_profile.certifications.map(item => <Chip key={item} label={item} />)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(DesignerProfile);
