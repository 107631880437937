import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import CategoryList from './views/Categories/Categories';
import CategoryDetail from './views/Categories/components/CategoryDetail/CategoryDetail';
import VideoList from './views/Videos/Videos';
import VideoDetail from './views/Videos/components/VideoDetail/VideoDetail';
import PostList from './views/Posts/Posts';
import PostDetail from './views/Posts/components/PostDetail/PostDetail';
import ProductList from './views/Products';
import ShowList from './views/Shows';
import ShowDetail from './views/Shows/components/ShowDetail';
import DesignerList from './views/Designers/Designers';
import DesignerDetail from './views/Designers/components/DesignerDetail/DesignerDetail';
import Account from './views/Account/Account';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';
import UserList from './views/UserList';

const Routes = () => {

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/videos"
      />
      {/* <Route
        component={Dashboard}
        exact
        path="/dashboard"
      /> */}
      {/*<Route*/}
      {/*  component={UserList}*/}
      {/*  exact*/}
      {/*  path="/users"*/}
      {/*/>*/}
      <Route
        component={CategoryList}
        exact
        path="/categories"
      />
      <Route
        component={CategoryDetail}
        exact
        path="/categories/:id"
      />
      <Route
        component={DesignerList}
        exact
        path="/designers"
      />
      <Route
        component={DesignerDetail}
        exact
        path="/designers/:id"
      />
      <Route
        component={PostList}
        exact
        path="/posts"
      />
      <Route
        component={PostDetail}
        exact
        path="/posts/:id"
      />
      <Route
        component={ProductList}
        exact
        path="/products"
      />
      <Route
        component={ShowList}
        exact
        path="/shows"
      />
      <Route
        component={ShowDetail}
        exact
        path="/shows/:id"
      />
      <Route
        component={VideoList}
        exact
        path="/videos"
      />
      <Route
        component={VideoDetail}
        exact
        path="/videos/:id"
      />
      {/* <Route
        component={Typography}
        exact
        path="/typography"
      />
      <Route
        component={Icons}
        exact
        path="/icons"
      /> */}
      <Route
        component={Account}
        exact
        path="/account"
      />
      {/* <Route
        component={Settings}
        exact
        path="/settings"
      />
      <Route
        component={SignUp}
        exact
        path="/sign-up"
      />
      <Route
        component={SignIn}
        exact
        path="/sign-in"
      /> */}
      <Route
        component={UnderDevelopment}
        exact
        path="/under-development"
      />
      <Route
        component={NotFound}
        exact
        path="/not-found"
      />
      <Route path="/stripe-dashboard" component={() => {
          window.location.href = 'https://dashboard.stripe.com/';
          return null;
      }}/>
      <Redirect to="/not-found" />
    </Switch>
  );

}

export default Routes
