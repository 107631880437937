export default theme => ({
  root: {},
  progressWrapper: {
    marginTop: theme.spacing(2)
  },
  dropZone: {
    marginTop: theme.spacing(2),
    maxHeight: theme.spacing(10)
  }
});
