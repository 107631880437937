import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Typography, Divider, TextField } from '@material-ui/core';
import {
  AccessTime as AccessTimeIcon,
  GetApp as GetAppIcon
} from '@material-ui/icons';
import { Paper } from 'components';
import styles from './styles';
import ReactPlayer from 'react-player'

import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { getVideo } from '../../../../graphql/queries';
import { createVideo, updateVideo } from '../../../../graphql/mutations';

const GetVideoQuery = gql`${getVideo}`
const CreateVideoQuery = gql`${createVideo}`
const UpdateVideoQuery = gql`${updateVideo}`

const VideoCard = (props) => {

  const { classes, className, video } = props
  const [ controls ] = useState(true)
  const [ publicVideo, setPublicVideo ] = useState([])
  const { data, loading } = useQuery(GetVideoQuery, {
    variables: { id: video.guid },
    fetchPolicy: 'cache-first'
  })

  const rootClassName = classNames(classes.root, className)
  const url = video.hlsUrl

  const createVideo = useMutation(CreateVideoQuery); 
  const updateVideo = useMutation(UpdateVideoQuery); 

  const fileConfig = {
    file: { 
      attributes: { 
        autoPlay: false,
        preload: 'none'
      }
      // hlsOptions: {
      //   autoStartLoad: false
      // } 
    } 
  }

  const allowedFields = ['id', 'title', 'description', 'url', 'thumbnail'];

  const onChange = (event, name) => {
    if(allowedFields.includes(name)){
      setPublicVideo({ ...publicVideo, [name]: event.target.value });
    }
  };

  const onBlur = () => {
    const input = Object.keys(publicVideo).reduce((object, key) => {
      if (allowedFields.includes(key) && publicVideo[key] !== null) {
        object[key] = publicVideo[key];
      }
      return object;
    }, {});
    const payload = {
      variables: {
        input: input
      }
    };
    updateVideo(payload);
  }

  useEffect(() => {
    let thumb = video.thumbNailUrl ? video.thumbNailUrl[0].slice(0,-11)+'0000025.jpg':null
    if(data.getVideo !== null && typeof data.getVideo !== 'undefined') setPublicVideo({...data.getVideo,'thumbnail':thumb,'url':video.hlsUrl ? video.hlsUrl:null})
  },[data, video.hlsUrl, video.thumbNailUrl])

  useEffect(() => {
    console.log('useffect..')
    if(!loading && data.getVideo === null){
      let thumb = video.thumbNailUrl ? video.thumbNailUrl[0].slice(11)+'0000025.jpg':null
      createVideo({ variables: { input: { id: video.guid, url: video.hlsUrl, thumbnail: thumb } } }).then(data=>setPublicVideo(data.data.createVideo)).catch(err=>console.log(err))
    }
  },[createVideo, data, loading, video.guid, video.hlsUrl, video.thumbNailUrl])

  return (
    <Paper className={rootClassName}>
      {video.hlsUrl === null ? (
        <Typography className={classes.description} variant="body1">
          Video currently transcoding...
        </Typography>
      ):(
        <ReactPlayer url={url} controls={controls} height='' width='100%' config={fileConfig} light={publicVideo.thumbnail}/>
      )}
      <div className={classes.details}>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="Title"
            margin="dense"
            onChange={event=>onChange(event,'title')}
            onBlur={event=>onBlur()}
            required
            value={publicVideo.title ? publicVideo.title:''}
            variant="outlined"
          />
        </div>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="Description"
            margin="dense"
            onChange={event=>onChange(event,'description')}
            onBlur={event=>onBlur()}
            required
            value={publicVideo.description ? publicVideo.description:''}
            variant="outlined"
          />
        </div>
      </div>
      <Divider />
      <div className={classes.stats}>
        <AccessTimeIcon className={classes.updateIcon} />
        <Typography className={classes.updateText} variant="body2">
          {new Date(video.startTime.slice(0,10)).toDateString()}
        </Typography>
        <GetAppIcon className={classes.downloadsIcon} />
        <Typography className={classes.downloadsText} variant="body2">
          {(JSON.parse(video.srcMediainfo).container.fileSize/1048576).toFixed(0)} MB
        </Typography>
      </div>
    </Paper>
  );
  
}

VideoCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired
};

export default withStyles(styles)(VideoCard);
