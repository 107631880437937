import React, {useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  withStyles,
  TextField,
  Grid, useMediaQuery, useTheme
} from "@material-ui/core";

import styles from "../../../views/Videos/styles";
import {onCreateTag} from "../../../hooks/useTags";
import {useApolloClient} from "react-apollo-hooks";

const NewTagModal = ({open, onClose, type}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const client = useApolloClient();
  const [text, setText] = useState(' ');

  const onChangeText = e => {
    if (e.target.value.length === 0) {
      return setText(' ');
    }
    setText(e.target.value);
  };

  const onSubmit = async () => {
    const payload = {
      input: {
        tag: text,
        type
      }
    };
    const {data} = await onCreateTag(client, payload);
    onClose(data);
  };

  return (<Dialog fullScreen={mobile} open={open} onClose={onClose}>
    <DialogTitle>
      <Typography>Create New Tag</Typography>
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField fullWidth variant={'outlined'} label={'Tag'} onChange={onChangeText}/>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onSubmit}>Submit</Button>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>)
};

export default withStyles(styles)(NewTagModal)
