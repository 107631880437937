import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  DialogTitle,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar
} from "@material-ui/core";
import { useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import { createProductCategory } from "../../../../../graphql/mutations";
import DialogPopup from "../../../../../components/DialogPopup";

const ExtractCategoriesDialog = ({
  open,
  items,
  itemColumns,
  categories,
  onClose,
  refetch,
}) => {
  const [state, setState] = useState({ category: "", sub_category: "" });
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [loading, setLoading] = useState(false);

  const CreateProductCategory = useMutation(gql(createProductCategory));

  const onFormControlChange = (controlName, value) => {
    setState({ ...state, [controlName]: value });
  };

  const onSubmit = async () => {
    if (state.category === "") {
      return setSnack({ open: true, message: "Missing category!" });
    }

    setLoading(true);

    const creating_categories = items.reduce((arr, item) => {
      const categoryName = item[state.category];

      const foundCategory = arr.filter(e => e.category === categoryName);

      if (state.sub_category !== "") {
        const subCategoryName = item[state.sub_category];

        if (foundCategory.length === 0) {
          return [
            ...arr,
            { category: categoryName, sub_categories: [subCategoryName] }
          ];
        } else {
          return [
            ...arr.filter(e => e.category !== categoryName),
            {
              category: categoryName,
              sub_categories: [
                ...foundCategory[0].sub_categories.filter(
                  e => e !== subCategoryName
                ),
                subCategoryName
              ]
            }
          ];
        }
      } else {
        if (foundCategory.length === 0) {
          return [...arr, { category: categoryName }];
        }
        return arr;
      }
    }, []);
    let count = 0;

    for (let cat of creating_categories) {
      let mainCategory;
      if (categories.filter(c => c.name === cat.category).length === 0) {
        const payload = {
          name: cat.category,
          slug: cat.category.toLowerCase().split(' ').join('_'),
          isMainCategory: true,
        };
        const res = await CreateProductCategory({variables: {input: payload}});
        mainCategory = res.createProductCategory;
        count++;
        console.log('created category : ', mainCategory);
      } else {
        mainCategory = categories.filter(c => c.name === cat.category)[0]
      }

      if (state.sub_category !== ''){
        for (let sub of cat.sub_categories) {
          if (categories.filter(c => c.name === sub).length === 0) {
            const payload = {
              name: sub,
              slug: sub.toLowerCase().split(' ').join('_'),
              productCategoryMainCategoryId: mainCategory.id,
            };
            count++;
            const res = await CreateProductCategory({variables: {input: payload}});
            console.log('created sub category : ', res);
          }
        }
      }
    }
    refetch();
    setLoading(false);
    onClose(count);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>
          <Typography component={"div"}>Extract Categories</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl variant={"outlined"} fullWidth disabled={loading}>
                <InputLabel variant={"filled"}>Main Categories *</InputLabel>
                <Select
                  value={state.category}
                  onChange={e =>
                    onFormControlChange("category", e.target.value)
                  }
                >
                  <MenuItem value={""}>None</MenuItem>
                  {itemColumns.map(column => (
                    <MenuItem key={column} value={column}>
                      {column}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl variant={"outlined"} fullWidth disabled={loading}>
                <InputLabel variant={"filled"}>Sub Categories</InputLabel>
                <Select
                  value={state.sub_category}
                  onChange={e =>
                    onFormControlChange("sub_category", e.target.value)
                  }
                >
                  <MenuItem value={""}>None</MenuItem>
                  {itemColumns.map(column => (
                    <MenuItem key={column} value={column}>
                      {column}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {loading && <DialogPopup open={loading} loading loadingText={'Extracting...'} />}
            <Snackbar
              open={snack.open}
              message={snack.message}
              onClose={() => setSnack({ open: false, message: "" })}
              autoHideDuration={5000}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => onSubmit()}>Submit</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExtractCategoriesDialog;
