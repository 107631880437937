/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { AccountDetails } from './components';
import { Auth } from 'aws-amplify'

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

const Account = (props) => {
  //const [ state, updateState ] = useState({ tabIndex: 0 })

  const [ user, setUser ] = useState(false)
  const { classes } = props;

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user=> {
      setUser(user.attributes)
    }).catch(err=>console.log(err))
  },[])

  if(!user){
    return (<div>Loading</div>)
  }

  return (
      <div className={classes.root}>
        <AccountDetails user={user}/>
      </div>
  );

}

Account.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
