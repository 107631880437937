import {API} from "aws-amplify";
const apiName = 'StripeProductsAPI';


async function getData(apiName, path) {
  return await API.get(apiName, path)
}

async function postData(apiName, path, data) {
  return await API.post(apiName, path, {
    headers: {'Content-Type' : 'application/json'},
    body: data
  })
}

async function deleteData(apiName, path, data) {
  return await API.del(apiName, path, {
    headers: {'Content-Type': 'application/json'},
    body: data
  })
}

async function getProducts() {
  try {
    const res = await getData(apiName, '/stripe/products');
    return res.data ? res.data.data : res
  } catch (e) {
    console.log(e)
  }
}

async function getProductById(id) {
  try {
    const res = await getData(apiName, '/stripe/products/' + id)
    return res.data ? res.data.data : res
  } catch (e) {
    console.log(e)
  }
}

async function getSkus() {
  try {
    const res = await getData(apiName, '/stripe/sku')
    return res.data ? res.data.data : res
  } catch (e) {
    console.log(e)
  }
}

async function getSkuByProductId(id) {
  try {
    const res = await getData(apiName, `/stripe/sku/product/${id}`)
    return res.data ? res.data.data : res
  } catch (e) {
    console.log(e)
  }
}

async function getSkuById(id) {
  try {
    const res = await getData(apiName, `/stripe/sku/${id}`)
    return res.data ? res.data : res
  } catch (e) {
    console.log(e)
  }
}

async function createOrder(sku_ids, name, line1, city, state, country, postal_code, email) {
  const data = {
    items: sku_ids.map(({id, quantity}) => {
      return {sku_id: id, quantity: quantity}
    }),
    name,
    line1,
    city,
    state,
    country,
    postal_code,
    email
  };
  try {
    const res = await postData(apiName, `/stripe/orders`, data)
    console.log('create an order function: ', res)
    return res.data ? res.data : res
  } catch (e) {
    console.log(e)
  }
}

async function payOrder(order_id, stripe_token) {
  try {
    const res = await postData(apiName, `/stripe/pay/${order_id}`, {stripe_token})
    return res.data ? res.data : res
  } catch (e) {
    console.log(e)
  }
}

async function createStripeProduct({name, caption, description, attributes, url, active=true, images, type='good', metadata={}, package_dimensions={}, shippable=true}) {
  try {
    const res = await postData(apiName, `/stripe/products`, {name, caption, description, attributes, active, images, type, metadata, package_dimensions, shippable, url});
    return res.success? res.data: res
  } catch (e) {
    console.log(e);
  }
}

async function deleteStripeProduct(id) {
    const res = await deleteData(apiName, '/stripe/products', {id});
    return res.data? res.data : res;
}

async function createSku({inventory, price, product, attributes, image, metadata, package_dimensions, active}) {
  try {
    const res = await postData(apiName, `/stripe/sku`, {inventory, price, product, attributes, image, metadata, package_dimensions, active });
    return res.success? res.data: res;
  } catch (e) {
    console.log(e);
  }
}

async function charge() {
  try {
    const res = await postData(apiName, `/stripe/pay`, {})
    return res.data ? res.data.data : res
  } catch (e) {
    console.log(e)
  }
}

export {
  getProducts,
  getProductById,
  getSkus,
  getSkuByProductId,
  getSkuById,
  createOrder,
  payOrder,
  charge,
  getData,
  postData,
  createStripeProduct,
  createSku,
  deleteStripeProduct
}
