import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Typography,
  LinearProgress,
  TextField,
  Snackbar,
} from "@material-ui/core";


import {useMutation, useQuery} from "react-apollo-hooks";
import gql from 'graphql-tag';

import { VideoPlayer } from "../components/VideoDetail/components";
import {createVideoCast, updatePendingVideo, updateVideo} from "../../../graphql/mutations";
import {getDesigner} from "../../../graphql/queries";

const PendingVideoModal = ({ open, title, video, onClose, refetch }) => {
  const UpdatePendingVideo = useMutation(gql(updatePendingVideo));
  const UpdatetVideo = useMutation(gql(updateVideo));
  const CreateVideoCast = useMutation(gql(createVideoCast));
  const {data, refetch: refetchDesigner} = useQuery(gql(getDesigner), {variables: {id: video ? video.pendingVideoDesignerId: '' }});

  const [designer, setDesigner] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState({open: false, message: ''});
  const [disabled, setDisabled] = React.useState(true);

  React.useEffect(() => {
    if (!video) {
      return setDisabled(false);
    }
    if (data.getDesigner) {
      setDisabled(false);
      setDesigner(data.getDesigner);

      console.log('designer: ', data.getDesigner);
    }
  }, [data]);

  const onApprove = async () => {
    if (!video.video) {
      return setSnackbar({open: true, message: "No video file found. Request user to reupload the file or contact support!"});
    }

    setDisabled(true);

    try {

      let payload = {
        input: {
          id: video.video.id,
          owner: video.owner,
          videoCategoryId: video.pendingVideoCategoryId,
          videoShowId: video.pendingVideoShowId,
          duration: video.duration,
          season: video.season,
          episode: video.episode,
          thumbnail: video.thumbnail, //doble check
          description: video.description,
          title: video.title,
          status: 'active'
        }
      };

     await UpdatetVideo({variables: payload});

      payload = {
        input: {
          id: video.id,
          status: 'approved',
          // pendingVideoVideoId: video.video.id
        }
      };

      await UpdatePendingVideo({variables: payload});

      if (designer.videos.items.filter(item => item.video.id === video.video.id).length === 0) {
        payload = {
          input: {
            videoCastDesignerId: video.pendingVideoDesignerId,
            videoCastVideoId: video.video.id,
          }
        };

        await CreateVideoCast({variables: payload});
      }

      refetch();
      setSnackbar({open: true, message: 'Approved video'});
      onClose();
    } catch (e) {
      setSnackbar({open: true, message: 'Something went wrong!'});
      console.log(e);
    }
    setDisabled(false);
  };

  const onDisapprove = async () => {
    setDisabled(true);

    try {
      // remove video file, maybe? no???
      if (video.video) {
        const payload = {
          input: {
            id: video.video.id,
            status: 'inactive',
          }
        };

        await UpdatetVideo({variables: payload});
      }

      const payload = {
        input: {
          id: video.id,
          status: 'inactive',
        }
      };

      await UpdatePendingVideo({variables: payload});

      refetch();
      setSnackbar({open: true, message: "Successfully disapproved video!"});
      onClose();
    } catch (e) {
      setSnackbar({open: true, message: "Something went wrong !"});
      console.log(e);
    }

    setDisabled(false);
  };

  return (
    <>
      {disabled && <Dialog open={disabled}>
        <DialogContent>
         Saving...
          <LinearProgress />
        </DialogContent>
      </Dialog>}
      <Snackbar open={snackbar.open} message={snackbar.message} autoHideDuration={3000} onClose={() => setSnackbar({open: false, message: ''})}/>
      <Dialog open={open} fullScreen>
        <DialogTitle>{title ? title : "Pending Video"}</DialogTitle>
        <DialogActions>
          <Button variant={"outlined"} onClick={onApprove}>
            Approve
          </Button>
          <Button variant={"outlined"} onClick={onDisapprove}>
            Disapprove
          </Button>
          <Button variant={"outlined"} onClick={onClose}>
            Close
          </Button>
        </DialogActions>
        <DialogContent>
          {video ? (
            <Grid container spacing={3}>
              <Grid item xs={12} container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  style={{ paddingRight: 10 }}
                >
                  <Grid item xs={6}>
                    <Typography variant={"h6"}>Category: </Typography>
                    <Typography variant={"h6"}>Designer:</Typography>
                    <Typography variant={"h6"}>Show: </Typography>
                    <Typography variant={"h6"}>Duration:</Typography>
                    <Typography variant={"h6"}>Season:</Typography>
                    <Typography variant={"h6"}>Season:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography> {video.category.name}</Typography>
                    <Typography> {video.designer.name}</Typography>
                    <Typography> {video.show.title}</Typography>
                    <Typography> {video.duration}</Typography>
                    <Typography> {video.season}</Typography>
                    <Typography> {video.episode}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant={"outlined"}
                      name={"comment"}
                      multiline
                      rows={6}
                      placeholder={"Comment"}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {video.video ? (
                    <VideoPlayer video={video.video} />
                  ) : (
                    <Typography category={"caption"}>No file found.</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PendingVideoModal;
