import React, { useState, useEffect } from "react";
import {
  Collapse,
  IconButton,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@material-ui/icons";

import Portlet from "../../../components/Portlet";
import PortletHeader from "../../../components/PortletHeader";
import PortletContent from "../../../components/PortletContent";
import { VideosToolbar } from "../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment-timezone";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { listPendingVideos } from "../../../graphql/queries";
import PendingVideoModal from "./PendingVideoModal";

export default ({ classes, videos, refetch, loading, filterVideos }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("pending");
  const [pendingVideos, setPendingVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const {
    data: { listPendingVideos: data },
    loadingPendingVideos, refetch: refetchPendingVideos
  } = useQuery(gql(listPendingVideos), { limit: 100000 });

  useEffect(() => {
    if (data && data.items && videos && videos.length > 0) {
      data.items.forEach(item => {
        item.video = videos.filter(
          video =>
            video.src_video && video.src_video.indexOf(item.src_video) > 0
        )[0];
      });

      setPendingVideos(data.items);
    }
  }, [data, videos]);

  const statuses = ["Pending", "All", "Approved", "Inactive"];

  const onRefetch = () => {
    refetch();
    refetchPendingVideos();
  };

  const onSearch = event => {
    setPage(0);
    setSearch(event.target.value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, videos.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onFilterByStatus = stat => {
    setPage(0);
    setStatus(stat.toLowerCase());
  };

  return (
    <Portlet>
      <PortletHeader>
        <Typography>Pending Video</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </IconButton>
      </PortletHeader>
      <PortletContent>
        <Collapse in={open}>
          {open && (
            <>
              <VideosToolbar
                noUpload={true}
                refetch={onRefetch}
                statuses={statuses}
                filter={onFilterByStatus}
                search={onSearch}
              />
              <div className={classes.content}>
                {loading ||
                  (loadingPendingVideos && (
                    <div className={classes.progressWrapper}>
                      <LinearProgress />
                    </div>
                  ))}
                {pendingVideos.length === 0 && (
                  <Typography variant="h6">
                    There are no videos available
                  </Typography>
                )}
                {pendingVideos.length > 0 && (
                  <Portlet>
                    <PortletContent noPadding>
                      <PerfectScrollbar>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">GUID</TableCell>
                              <TableCell align="left">Title</TableCell>
                              <TableCell align="left">Desc</TableCell>
                              <TableCell align="left">Submitted date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pendingVideos
                              .filter(video =>
                                filterVideos(search, status, video)
                              )
                              .sort(function compare(a, b) {
                                // var dateA = new Date(a.startTime.slice(0, 16));
                                // var dateB = new Date(b.startTime.slice(0, 16));
                                var dateA = new Date(a.createdAt.slice(0, 16));
                                var dateB = new Date(b.createdAt.slice(0, 16));
                                return dateB - dateA;
                              })
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map(video => (
                                <TableRow
                                  className={classes.tableRow}
                                  hover
                                  key={video.id}
                                >
                                  <TableCell className={classes.tableCell}>
                                    <Link
                                      onClick={() => setSelectedVideo(video)}
                                    >
                                      {video.id}
                                    </Link>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {video.title}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {video.description}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {//moment(video.startTime.slice(0, 16) + '+0000')
                                    moment(
                                      video.createdAt.slice(0, 16) + "+0000"
                                    )
                                      .tz("America/Chicago")
                                      .format("llll")}
                                  </TableCell>
                                </TableRow>
                              ))}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 56 * emptyRows }}>
                                <TableCell colSpan={4} />
                              </TableRow>
                            )}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                colSpan={4}
                                count={videos.length}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                page={parseInt(page)}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                                SelectProps={{
                                  inputProps: { "aria-label": "Rows per page" },
                                  native: true
                                }}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </PerfectScrollbar>
                    </PortletContent>
                  </Portlet>
                )}
              </div>
              <PendingVideoModal open={!!selectedVideo} video={selectedVideo} refetch={onRefetch} onClose={() => setSelectedVideo(null)}/>
            </>
          )}
        </Collapse>
      </PortletContent>
    </Portlet>
  );
};
