import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ButtonGroup,
  Button,
  LinearProgress
} from "@material-ui/core";
import LinearDeterminate from "../ProgressBar";

const DialogPopup = ({open, title, children, buttonText, onClose, onSubmit, progress, loading=false, loadingText='Loading...', disabled}) => {
  return <Dialog open={open} onBackdropClick={loading? null : onClose}>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>
      {loading ? <>
        <DialogContentText>{loadingText}</DialogContentText>
        {progress? <LinearDeterminate progress={progress} /> :<LinearProgress />}
      </>: children}
    </DialogContent>
    <DialogActions>
      <ButtonGroup>
        {buttonText && !loading && <Button onClick={onSubmit} disabled={disabled}>{buttonText}</Button>}
        {onClose && !loading && <Button onClick={onClose}>Close</Button>}
      </ButtonGroup>
    </DialogActions>
  </Dialog>
};

export default DialogPopup
