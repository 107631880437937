import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid, LinearProgress } from '@material-ui/core';
import { PostDetails } from './components'
import uuid from 'uuid'

//graphql stuff
import gql from 'graphql-tag'
import { getPost } from '../../../../graphql/queries'
import { useQuery } from 'react-apollo-hooks'

const getPostQuery = gql`${getPost}`

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    paddingBottom: '5px'
  }
});

const PostDetail = (props) => {
  const { classes } = props
  const { data, loading } = useQuery(getPostQuery, { variables: { id: props.match.params.id }})
  const [ post, setPost ] = useState({id: uuid(),title:'',content:'',status:'draft',thumbnail:''})

  useEffect(() => {
    if(data.getPost && data.getPost !== 'undefined') setPost(data.getPost)
  },[data])
  
  return (
    <div className={classes.root}>
        {( (loading || post.thumbnail === '') && props.match.params.id !== 'new') ? (
          <div className={classes.progressWrapper}>
            <LinearProgress />
          </div>
        ):(
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <PostDetails post={post} {...props}/>
          </Grid>
        </Grid>
        )}
    </div>
  );
}

PostDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PostDetail);