import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, Grid, Button, TextField } from '@material-ui/core';
import ProgressBar from 'components/ProgressBar'
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid'

//graphql
import { useQuery, useMutation } from 'react-apollo-hooks';
import { getUser } from 'graphql/queries';
import { updateUser, createUser } from 'graphql/mutations';
import gql from 'graphql-tag';

const UpdateUserQuery = gql`
  ${updateUser}
`;
const GetUserQuery = gql`
  ${getUser}
`;

const CreateUserQuery = gql`
  ${createUser}
`;

const Account = (props) => {
  const userData = props.user

  const [ user, setUser ] = useState({first_name:'',last_name:'',phone:''})

  const { data, loading } = useQuery(GetUserQuery, {
    variables: { id: userData.sub },
    fetchPolicy: 'network-only'
  });

  const [emailUpdated, setEmailUpdated] = useState(false);
  const [email, setEmail] = useState('loading...');

  const updateProfile = useMutation(UpdateUserQuery);

  const [ selectedFile, setSelectedFile ] = useState(null)
  const [ loaded, setLoaded ] = useState(null)
  const [ disabled, setDisabled ] = useState(false)
  const [ updatedPhoto, setUpdatedPhoto ] = useState(false)

  const onChangeHandler=event=>{
    setSelectedFile(event.target.files[0])
    setUpdatedPhoto(true)
  }
  
  const allowedFields = ['id', 'first_name', 'last_name', 'email', 'phone', 'photo_url'];

  const updateProfileQuery = useCallback(async () => {

    const updateEmail = async () => {
      let currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
      });
      if (email !== currentUser.attributes.email) {
        Auth.updateUserAttributes(currentUser, {
          email: email
        });
      }
    };
    
    const input = Object.keys(user).reduce((object, key) => {
      if (allowedFields.includes(key) && user[key] !== null) {
        object[key] = user[key];
      }
      return object;
    }, {});
    const payload = {
      variables: {
        input: input
      }
    };
    if (emailUpdated) {
      updateEmail();
    }

    await updateProfile(payload);
  },[allowedFields, email, emailUpdated, updateProfile, user]);

  useEffect(() => {
    if(updatedPhoto) updateProfileQuery()
  },[updateProfileQuery, updatedPhoto, user.photo_url])

  const uploadFile = () => {
    setDisabled(true)
    let ext = selectedFile.name.split('.').pop();
    let key = uuid()+'.'+ext;
    Storage.put('images/'+key, selectedFile, {
      bucket: 'hdtv-prod',
      level: 'public',
      progressCallback(progress) {
        setLoaded(progress)
      },
    }).then((res) => {
      removePhoto()
      setDisabled(false)
      setLoaded(null)
      setUser({...user, photo_url: res.key})
    })
  
  }

  const removePhoto = () => {
    if(user.photo_url){
      Storage.remove(user.photo_url, {
        bucket: 'hdtv-prod',
        level: 'public'
      }).then(() => true )
        .catch(err => console.log(err))
    }else{
      return true;
    }
  }

  const onChangeEmail = event => {
    setEmailUpdated(true);
    setEmail(event.target.value);
  };
  
  const onChange = (event, name) => {
    setUser({ ...user, [name]: event.target.value });
  };

  const onSubmit = async () => {
    updateProfileQuery();
  };

  useEffect(() => {
    setEmail(userData.email);
  }, [userData.email]);

  const createUser = useMutation(CreateUserQuery); 

  useEffect(() => {
    if(data.getUser !== null && typeof data.getUser !== 'undefined') setUser(data.getUser)
  },[data])

  useEffect(() => {
    if(!loading && data.getUser === null){
      createUser({ variables: { input: { id: userData.sub, email:userData.email } } }).then(data=>setUser(data.data.createUser)).catch(err=>console.log(err))
    }
  },[createUser, data, loading, userData.email, userData.sub])

 
  const { classes, className, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
          <Portlet {...rest} className={rootClassName}>
            <PortletContent style={{padding:'0px',marginBottom:'-3px'}}>
              {user.photo_url && (
                <img height='100%' width='100%' src={'https://hdtv-prod.s3.amazonaws.com/public/'+user.photo_url} alt="profile"/>
              )}
              <div className={classes.progressWrapper}>
                {loaded && 
                  <div className={classes.row}>
                    <ProgressBar progress={loaded} />
                  </div>
                }
              </div>
            </PortletContent>
            <PortletFooter>
              <input color="primary" size="small" variant="outlined" type="file" name="file" onChange={onChangeHandler}/>
              <Button color="primary" size="small" variant="outlined" onClick={uploadFile} disabled={!selectedFile || disabled}>
                Upload
              </Button>
            </PortletFooter>
          </Portlet>
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
          <Portlet {...rest} className={rootClassName}>
            <PortletHeader>
              <PortletLabel
                subtitle="The information can be edited"
                title="Profile"
              />
            </PortletHeader>
            <PortletContent noPadding>
              <form autoComplete="off" >
                <div className={classes.field}>
                  <TextField
                    className={classes.textField}
                    label="First name"
                    margin="dense"
                    onChange={event=>onChange(event,'first_name')}
                    required
                    value={user.first_name ? user.first_name:''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    label="Last name"
                    margin="dense"
                    onChange={event=>onChange(event,'last_name')}
                    required
                    value={user.last_name ? user.last_name:''}
                    variant="outlined"
                  />
                </div>
                <div className={classes.field}>
                  <TextField
                    className={classes.textField}
                    label="Email Address"
                    margin="dense"
                    onChange={event=>onChangeEmail(event)}
                    required
                    value={email ? email:''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    label="Phone Number"
                    margin="dense"
                    onChange={event=>onChange(event,'phone')}
                    value={user.phone ? user.phone:''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    label="Photo URL"
                    margin="dense"
                    onChange={event=>onChange(event,'photo_url')}
                    value={user.photo_url ? user.photo_url:''}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </form>
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
              <Button color="primary" variant="contained" onClick={onSubmit}>
                Save details
              </Button>
            </PortletFooter>
          </Portlet>
          </Grid>
        </Grid>
  );

}

Account.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object
};

export default withStyles(styles)(Account);
