import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { Grid, LinearProgress } from "@material-ui/core";
import { DesignerDetails } from "./components";
import uuid from "uuid";

//graphql stuff
import gql from "graphql-tag";
import { getDesigner } from "graphql/queries";
import { useQuery } from "react-apollo-hooks";

const getDesignerQuery = gql`
  ${getDesigner}
`;

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    paddingBottom: "5px"
  }
});

const DesignerDetail = props => {
  const { classes } = props;
  const { data, loading } = useQuery(getDesignerQuery, {
    variables: { id: props.match.params.id }
  });
  const [designer, setDesigner] = useState({
    id: uuid(),
    email: "",
    name: "",
    bio: "",
    phone: "",
    location: "",
    photo_url: ""
  });

  useEffect(() => {
    if (data.getDesigner && data.getDesigner !== "undefined")
      setDesigner(data.getDesigner);
  }, [data]);

  return (
    <div className={classes.root}>
      {(loading || designer.name === "") && props.match.params.id !== "new" ? (
        <div className={classes.progressWrapper}>
          <LinearProgress />
        </div>
      ) : (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <DesignerDetails designer={designer} {...props} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

DesignerDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DesignerDetail);
