export default theme => ({
  fullWidth: {
    width: '100%'
  },
  header: {
    height: theme.spacing(30),
    '& img': {
      width: '100%',
      height: theme.spacing(30),
      objectFit: 'cover'
    }
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    top: 25
  },
  caption: {
    color: '#66788A',
    fontSize: 12,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    letterSpacing: 0.3
  }
});
