import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Button, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';

//graphql
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { updateShow } from 'graphql/mutations';
import { createShow } from 'graphql/mutations';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

// Component styles
import styles from './styles';
import 'react-quill/dist/quill.snow.css'

const UpdateShowQuery = gql`${updateShow}`
const CreateShowQuery = gql`${createShow}`

const ShowDetails = (props) => {
    const [ show, setShow ] = useState({...props.show})
    const { classes, className } = props;
    const rootClassName = classNames(classes.root, className);
    const [ create, setCreate ] = useState(false)
    const [ disabled, setDisabled ] = useState(false)

    const updateShow = useMutation(UpdateShowQuery);
    const createShow = useMutation(CreateShowQuery);
    
    const allowedFields = ['id', 'title', 'description', 'release_date'];
    const handleSubmit = async () => {
      setDisabled(true)
      const input = Object.keys(show).reduce((object, key) => {
        if (allowedFields.includes(key) && show[key] !== null) {
          object[key] = show[key];
        }
        return object;
      }, {});
      const payload = {
        variables: {
          input: input
        }
      };
      await ((create) ? createShow(payload):updateShow(payload));
      props.history.goBack()
    }

    useEffect(() => {
      console.log(props.show)
      if(props.match.params.id === 'new'){
        setCreate(true)
      }
    },[props.show, props.match.params.id])

    function handleDateChange(date) {
      setShow({...show, release_date: date});
    }

    const onChange = (event, name) => {
      console.log(event.target.value, name)
      if(allowedFields.includes(name)){
        setShow({ ...show, [name]: event.target.value });
        console.log(disabled)
      }
    };

    return (
      <Portlet
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle="Show Information"
            title={show.title ? show.title:'Title'}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="mui-pickers-date"
              label="Release Date"
              value={show.release_date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'release date',
              }}
            />
          </MuiPickersUtilsProvider>
        </PortletHeader>
        <PortletContent>
          <form className={classes.form}>
            <TextField
              className={classes.textField}
              label="Show Title"
              name="title"
              onChange={event =>
                onChange(event, 'title')
              }
              type="text"
              value={show.title ? show.title:''}
              variant="standard"
            />
            <TextField
              className={classes.textField}
              label="Description"
              name="description"
              onChange={event =>
                onChange(event, 'description')
              }
              type="text"
              value={show.description ? show.description:''}
              variant="standard"
              multiline={true}
              rows="5"
            />
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleSubmit}
            disabled={disabled}
          >
            Save
          </Button>
        </PortletFooter>
      </Portlet>
    );
}

ShowDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ShowDetails);
