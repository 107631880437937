export default theme => ({
  root: {
    margin: '20px 0'
  },
  tabContainer: {
    marginTop: 40
  },
  tabIndicator: {
    backgroundColor: '#5686ab',
    height: '100%',
    opacity: 0.5
  },
  centered: {
    textAlign: 'center',
    '& h2': {
      lineHeight: theme.spacing(0.25)
    },
    '& button': {
      maxWidth: 400
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  textField: {},
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  button: {
    marginTop: 10
  }
});
