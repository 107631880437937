import React, {useContext, useEffect, useState} from 'react';
import {
  Grid,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  Snackbar, LinearProgress
} from '@material-ui/core';

import {
  PersonOutline,
  ExpandLess,
  ExpandMore,
  ContactMailOutlined,
  PermMediaOutlined,
  InfoOutlined,
  ImageOutlined
} from '@material-ui/icons';

import { updateDesignerData } from '../../../../../hooks/useUpdateDesigner';

import BioInfoForm from './forms/BioInfoForm';
import ContactForm from './forms/ContactForm';
import NameForm from './forms/NameForm';
import SocialMediaForm from './forms/SocialMediaForm';
import SummaryComponent from './forms/Summary';
import {DesignerContext} from "../../../../../views/Designers/components/DesignerDetail/components/DesignerDetails/DesignerDetails";
import ImageForm from "./forms/ImageForm";

const PublicProfile = ({ classes }) => {
  const [currentForm, setCurrentForm] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [dialog, setDialog] = useState({});
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const {designer, refetch} = useContext(DesignerContext);

  const updateProfile = async (data, closeForm = true) => {
    try {
      if (data) {
        openDialog('Saving...', '', true);

        const draft_profile = designer.draft_profile ? { ...designer.draft_profile, ...data, status: 'draft' } : { ...data, status: 'draft' };

        delete draft_profile.__typename;

        for (let key of Object.keys(draft_profile)) {
          if (draft_profile[key] === '') {
            delete draft_profile[key];
          }
        }
        const _data = Object.assign({}, draft_profile);
        delete _data.__typename;
        await updateDesignerData(designer.id, null, null, {
          bio: _data.bio,
          photo_url: _data.photo_url,
          name: _data.name,
          profile: { ..._data, status: 'published' },
          draft_profile: { ..._data, status: 'published' }
        });
        refetch();
      }
      handleDialogClose();
      openDialog('Saved!');
    } catch (e) {
      console.log(e);
      openDialog('Something went wrong! Please try again!');
    }
    if (closeForm) setCurrentForm('');
    window.scrollTo(0, 0);
  };

  const openDialog = (title, content, loading = false) => {
    setDialog({ open: true, title, content, loading });
  };

  const handleDialogClose = () => {
    setDialog({});
  };

  const onListButtonClick = formName => () => {
    return formName === currentForm ? setCurrentForm('') : setCurrentForm(formName);
  };

  const renderNameForm = () => <NameForm classes={classes} designer={designer} setSnackbar={setSnackbar} onSubmit={updateProfile} />;

  const renderContactForm = () => <ContactForm classes={classes} designer={designer} onSubmit={updateProfile} setSnackbar={setSnackbar} />;

  const renderSocialMediaForm = () => <SocialMediaForm classes={classes} designer={designer} onSubmit={updateProfile} setSnackBar={setSnackbar} />;

  const renderImageForm = () => <ImageForm classes={classes} designer={designer} onSubmit={updateProfile} setSnackbar={setSnackbar} />;

  const renderBioForm = () => <BioInfoForm classes={classes} designer={designer} onSubmit={updateProfile} />;

  const renderSummary = () =>
    currentForm === '' ? <SummaryComponent classes={classes} designer={designer} refetch={refetch} setSnackbar={setSnackbar} /> : null;

  return (
    <>
      {mobile && (
        <List>
          <ListItem button onClick={onListButtonClick('names')}>
            <ListItemIcon>
              <PersonOutline />
            </ListItemIcon>
            <ListItemText primary={'Public Profile'} />
            {currentForm === 'names' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={currentForm === 'names'}>{renderNameForm()}</Collapse>
          <ListItem button onClick={onListButtonClick('contact')}>
            <ListItemIcon>
              <ContactMailOutlined />
            </ListItemIcon>
            <ListItemText primary={'Contact Information'} />
            {currentForm === 'contact' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={currentForm === 'contact'}>{renderContactForm()}</Collapse>
          <ListItem button onClick={onListButtonClick('social-media')}>
            <ListItemIcon>
              <PermMediaOutlined />
            </ListItemIcon>
            <ListItemText primary={'Social Media'} />
            {currentForm === 'social-media' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <ListItem button onClick={onListButtonClick('images')}>
            <ListItemIcon>
              <ImageOutlined />
            </ListItemIcon>
            <ListItemText primary={'Profile Images'} />
            {currentForm === 'images' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={currentForm === 'images'}>{renderImageForm()}</Collapse>
          <ListItem button onClick={onListButtonClick('bio-information')}>
            <ListItemIcon>
              <InfoOutlined />
            </ListItemIcon>
            <ListItemText primary={'Biographical Information'} />
            {currentForm === 'bio-information' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={currentForm === 'bio-information'}>{renderBioForm()}</Collapse>
          <Collapse in={currentForm === ''}>{renderSummary()}</Collapse>
        </List>
      )}
      {!mobile && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <List>
              <ListItem button onClick={onListButtonClick('names')}>
                <ListItemIcon>
                  <PersonOutline />
                </ListItemIcon>
                <ListItemText primary={'Public Profile'} />
                {currentForm === 'names' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <ListItem button onClick={onListButtonClick('contact')}>
                <ListItemIcon>
                  <ContactMailOutlined />
                </ListItemIcon>
                <ListItemText primary={'Contact Information'} />
                {currentForm === 'contact' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <ListItem button onClick={onListButtonClick('social-media')}>
                <ListItemIcon>
                  <PermMediaOutlined />
                </ListItemIcon>
                <ListItemText primary={'Social Media'} />
                {currentForm === 'social-media' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <ListItem button onClick={onListButtonClick('images')}>
                <ListItemIcon>
                  <ImageOutlined />
                </ListItemIcon>
                <ListItemText primary={'Profile Images'} />
                {currentForm === 'images' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <ListItem button onClick={onListButtonClick('bio-information')}>
                <ListItemIcon>
                  <InfoOutlined />
                </ListItemIcon>
                <ListItemText primary={'Biographical Information'} />
                {currentForm === 'bio-information' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={8}>
            <Collapse in={currentForm === 'names'}>{renderNameForm()}</Collapse>
            <Collapse in={currentForm === 'contact'}>{renderContactForm()}</Collapse>
            <Collapse in={currentForm === 'social-media'}>{renderSocialMediaForm()}</Collapse>
            <Collapse in={currentForm === 'images'}>{renderImageForm()}</Collapse>
            <Collapse in={currentForm === 'bio-information'}>{renderBioForm()}</Collapse>
            <Collapse in={currentForm === ''}>{renderSummary()}</Collapse>
          </Grid>
        </Grid>
      )}

      <Snackbar open={snackbar.open} message={snackbar.message} autoHideDuration={3000} onClose={() => setSnackbar({ open: false, message: '' })} />

      <Dialog open={!!dialog.open}>
        <DialogTitle>{dialog.title ? dialog.title : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content ? dialog.content : ''}</DialogContentText>
          {dialog.loading && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Grid container alignContent={'center'} justify={'center'} alignItems={'center'}>
            {!dialog.loading && <Button onClick={handleDialogClose}>OK</Button>}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PublicProfile;
