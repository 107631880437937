import React, { useEffect, useState } from "react";
import {
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonGroup,
  Button,
  DialogTitle,
  Select,
  MenuItem,
  LinearProgress,
  Grid,
  TextField,
  FormControl,
  Checkbox,
  IconButton,
  Snackbar,
  InputLabel,
  FormControlLabel,
  Typography
} from "@material-ui/core";
import XLSX from "xlsx";

import styles from "./styles";
import uuid from "uuid";
import { AddCircleOutline } from "@material-ui/icons";
import DialogPopup from "../../../../components/DialogPopup";
import gql from "graphql-tag";
import { getProduct } from "../../../../graphql/queries";
import { createSku, createStripeProduct } from "../../../../services/stripe";
import { createProduct } from "../../../../graphql/mutations";
import { useApolloClient } from "react-apollo-hooks";
import ExtractCategoriesDialog from "./components/ExtractCategories";

const ImportSpreadSheetModal = ({ workbook, onClose, categories, refetch }) => {
  // apollo state
  const client = useApolloClient();

  // page state
  const [itemSheetName, setItemSheetName] = useState("");
  const [imageSheetName, setImageSheetName] = useState("");
  const [spreadSheets, setSpreadSheets] = useState({
    items: null,
    images: null
  });
  const [itemColumns, setItemColumns] = useState([]);
  const [loaded, setLoaded] = useState(0);

  const [keyItemSheet, setKeyItemSheet] = useState("");
  const [keyImageSheet, setKeyImageSheet] = useState("");

  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [extractCategories, setExtractCategories] = useState(false);

  const [state, setState] = useState({
    id: "",
    upc: "",
    name: "",
    caption: "",
    description: "",
    price: "",
    designer: "",
    package_dimensions: null,
    attributes: [],
    metadata: [],
    image: "",
    category: "",
    sub_category: "",
    related_to: "",
    collection: '',
  });

  const onPrepareImporting = () => {
    const items = XLSX.utils.sheet_to_json(workbook.Sheets[itemSheetName]);
    const images =
      imageSheetName.length > 0
        ? XLSX.utils.sheet_to_json(workbook.Sheets[imageSheetName])
        : null;
    setItemColumns(
      items.reduce(
        (arr, item) => [...new Set([...arr, ...Object.keys(item)])],
        []
      )
    );

    setSpreadSheets({ items, images });
  };
  useEffect(() => console.log(itemColumns), [itemColumns]);

  const disabled = () =>
    itemSheetName.length === 0 ||
    (itemSheetName.length > 0 &&
      imageSheetName.length > 0 &&
      (keyItemSheet.length === 0 || keyImageSheet.length === 0));

  const onFormControlChange = (controlName, value) => {
    const names = controlName.split(".");
    if (names.length > 1) {
      switch (names[0]) {
        case "metadata":
          const _state = Object.assign({}, state);
          const index = Number(names[1]);
          _state.metadata[index].value = value;
          _state.metadata[index].key = value.toLowerCase().split(' ').join('_');
          return setState(_state);
        case "dimension":
          return setState({
            ...state,
            package_dimensions: {
              ...state.package_dimensions,
              [names[1]]: value
            }
          });
        default:
          throw new Error("Missing Argument!");
      }
    }
    setState({ ...state, [names[0]]: value });
  };

  const onExtractCategories = () => {
    setExtractCategories(true);
  };

  const onSubmit = async () => {
    if (state.name === "") {
      return setSnack({ open: true, message: "Name is required!" });
    }

    if (state.price === "") {
      return setSnack({ open: true, message: "Price is required!" });
    }

    setLoading(true);
    try {
      let count = 0;
      for (let item of spreadSheets.items) {
        const payload = Object.assign({}, state);
        if (payload.package_dimensions)
          payload.package_dimensions = Object.assign(
            {},
            state.package_dimensions
          );
        if (payload.metadata.length > 0)
          payload.metadata = [
            ...state.metadata.map(item => Object.assign({}, item))
          ];
        if (payload.attributes.length > 0)
          payload.attributes = [...state.attributes];

        for (let key of Object.keys(payload)) {
          if (!payload[key] || payload[key].length === 0) {
            delete payload[key];
          } else {
            if (
              key !== "attributes" &&
              key !== "metadata" &&
              key !== "package_dimensions"
            ) {
              payload[key] = item[payload[key]];
            } else {
              switch (key) {
                case "attributes":
                  break;
                case "metadata":
                  for (let meta of payload[key]) {
                    meta.value = item[meta.value];
                    delete meta.id;
                  }
                  break;
                case "package_dimensions":
                  payload.package_dimensions.weight =
                    item[payload.package_dimensions.weight];
                  payload.package_dimensions.height =
                    item[payload.package_dimensions.height];
                  payload.package_dimensions.width =
                    item[payload.package_dimensions.width];
                  payload.package_dimensions.length =
                    item[payload.package_dimensions.length];
                  break;
              }
            }
            if (!payload[key] || payload[key].length === 0) {
              delete payload[key];
            }
          }
        }

        if (payload.sub_category) {
          const category = categories.filter(
            e => e.name === payload.sub_category
          );
          if (category.length > 0) {
            payload.sub_category = category[0].id;
          } else {
            delete payload.sub_category;
          }
        }

        if (payload.category) {
          const category = categories.filter(
            e => e.name === payload.category && !e.mainCategory
          );
          if (category.length > 0) {
            payload.category = category[0].id;
          } else {
            if (payload.sub_category) {
              payload.category = payload.sub_category;
            } else delete payload.category;
          }
        }

        if (imageSheetName !== "") {
          let images = spreadSheets.images.filter(
            e => e[keyImageSheet] === item[keyItemSheet]
          );
          images = images.length > 0 ? images[0] : null;

          if (images) {
            delete images[keyImageSheet];
            let _images = [];
            for (let key of Object.keys(images)) {
              if (images[key].indexOf("http") > -1) {
                _images = [..._images, images[key].trim().split(' ').join('%20')];
              }
            }
            payload.images = _images;
          }
        }

        const res = await addProduct(payload);
        setSnack({
          open: true,
          message: `${res}. ${++count}/${spreadSheets.items.length}`
        });
        setLoaded(count);
      }
    } catch (e) {
      console.log("error: ", e);
      setSnack({ open: true, message: e.message ? e.message : e });
    }
    setLoading(false);
    setLoaded(0);
  };

  const addProduct = async payload => {
    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     console.log(payload);
    //     resolve('Imported '+payload.name)
    //   }, 1000);
    // });

    if (isNaN(payload.price) || payload.price === "") {
      throw new Error("Price is invalid!");
    }

    payload.price = Math.ceil(payload.price * 100);

    if (payload.package_dimensions) {
      for (let key of Object.keys(payload.package_dimensions)){
        if (payload.package_dimensions[key] === '' || isNaN(payload.package_dimensions[key])) {
          delete payload.package_dimensions;
          break;
        }
        payload.package_dimensions[key] = Number(payload.package_dimensions[key]).toFixed(2)
      }
    }

    if (payload.images && payload.images.length > 8) {
      payload.images = payload.images.slice(0, 8);
    }

    const _product =
      payload.id && payload.id.length > 0
        ? await client.query({
            query: gql(getProduct),
            variables: { id: payload.id },
            fetchPolicy: "network-only"
          })
        : { data: {} };

    if (_product.data.getProduct && _product.data.getProduct.id) {
      return "Item Number exists";
    }

    const metadata = payload.metadata && payload.metadata.length > 0 ? {} : "";

    if (payload.metadata)
      for (let data of payload.metadata) {
        metadata[data.key] = data.value;
      }


    let data = {
      id: payload.id,
      name: payload.name,
      caption: payload.caption,
      description: payload.description,
      attributes: payload.attributes
        ? payload.attributes.map(attribute => attribute.trim())
        : [],
      images: payload.images ? payload.images : null,
      metadata,
      package_dimensions: payload.package_dimensions
    };

    for (let key of Object.keys(data)) {
      if (
        !data[key] ||
        data[key] === "" ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        delete data[key];
      }
    }

    const stripe_prod = await createStripeProduct(data);
    console.log("created stripe product: ", stripe_prod);
    if (stripe_prod.success === false) throw new Error(stripe_prod.data);

    data = {
      ...data,
      upc: payload.upc,
      price: payload.price,
      collection: payload.collection,
      image:
        payload.images && payload.images.length > 0
          ? payload.images[0]
          : payload.image,
      productCategoryId: payload.sub_category ? payload.sub_category : payload.category? payload.category: "",
      mainProductCategoryId: payload.category ? payload.category : "",
      product_id: stripe_prod.id,
      meta: data.metadata ? JSON.stringify(data.metadata) : "",
      active: true,
      box_size: data.package_dimensions
        ? JSON.stringify(data.package_dimensions)
        : "",
      designer: payload.designer
    };
    const images = payload.images;
    delete data.images;
    delete data.metadata;
    delete data.product;
    delete data.package_dimensions;
    for (let key of Object.keys(data)) {
      if (
        !data[key] ||
        data[key] === "" ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        delete data[key];
      }
    }

    let res = await client.mutate({
      mutation: gql(createProduct),
      variables: { input: data }
    });
    console.log("created product: ", res);

    data.product = stripe_prod.id;
    data.image = images && images.length > 0 ? images[0] : payload.image;
    delete data.collection;
    delete data.product_id;
    delete data.attributes;
    delete data.active;
    delete data.productCategoryId;
    delete data.mainProductCategoryId;

    console.log("sku data: ", data);

    res = await createSku(data);

    console.log("created sku", res);
    if (res.success === false) throw new Error(res.data);
    return "Imported " + payload.name;
  };

  return workbook ? (
    <Dialog open={!!workbook} fullScreen>
      <DialogTitle>Import Spreadsheet</DialogTitle>
      <DialogContent>
        {workbook ? (
          !spreadSheets.items ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>
                    Select spread sheet for items *
                  </InputLabel>
                  <Select
                    value={itemSheetName}
                    onChange={e => {
                      setKeyItemSheet("");
                      setKeyImageSheet("");
                      setItemSheetName(e.target.value);
                    }}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {workbook.SheetNames.map(sheetName => (
                      <MenuItem key={sheetName} value={sheetName}>
                        {sheetName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {workbook.SheetNames.length > 1 && (
                <Grid item xs={12}>
                  <FormControl
                    variant={"outlined"}
                    fullWidth
                    disabled={loading}
                  >
                    <InputLabel variant={"filled"}>
                      Select spread sheet for images
                    </InputLabel>
                    <Select
                      value={imageSheetName}
                      onChange={e => {
                        setKeyItemSheet("");
                        setKeyImageSheet("");
                        setImageSheetName(e.target.value);
                      }}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {workbook.SheetNames.map(sheetName => (
                        <MenuItem key={sheetName} value={sheetName}>
                          {sheetName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {imageSheetName.length > 0 && itemSheetName.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography variant={"caption"}>
                      Mapping key for item spread
                    </Typography>
                    <FormControl
                      variant={"outlined"}
                      fullWidth
                      disabled={loading}
                    >
                      <Select
                        value={keyItemSheet}
                        onChange={e => setKeyItemSheet(e.target.value)}
                      >
                        <MenuItem value={""}>None</MenuItem>
                        {XLSX.utils
                          .sheet_to_json(workbook.Sheets[itemSheetName])
                          .reduce(
                            (arr, item) => [
                              ...new Set([...arr, ...Object.keys(item)])
                            ],
                            []
                          )
                          .map(sheetName => (
                            <MenuItem key={sheetName} value={sheetName}>
                              {sheetName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"caption"}>
                      Mapping key for image spread
                    </Typography>
                    <FormControl
                      variant={"outlined"}
                      fullWidth
                      disabled={loading}
                    >
                      <Select
                        value={keyImageSheet}
                        onChange={e => setKeyImageSheet(e.target.value)}
                      >
                        <MenuItem value={""}>None</MenuItem>
                        {XLSX.utils
                          .sheet_to_json(workbook.Sheets[imageSheetName])
                          .reduce(
                            (arr, item) => [
                              ...new Set([...arr, ...Object.keys(item)])
                            ],
                            []
                          )
                          .map(sheetName => (
                            <MenuItem key={sheetName} value={sheetName}>
                              {sheetName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Button
                variant={"outlined"}
                disabled={disabled()}
                onClick={onPrepareImporting}
              >
                Continue
              </Button>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              {extractCategories && (
                <ExtractCategoriesDialog
                  open={extractCategories}
                  items={spreadSheets.items}
                  itemColumns={itemColumns}
                  onClose={count => {
                    if (!isNaN(count))
                      setSnack({
                        open: true,
                        message: `${count} categories created!`
                      });
                    setExtractCategories(false);
                  }}
                  categories={categories}
                  refetch={refetch}
                />
              )}
              <Grid item xs={12}>
                <Button variant={"outlined"} onClick={onExtractCategories}>
                  Extract categories
                </Button>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Item number</InputLabel>
                  <Select
                    value={state.id}
                    onChange={e => onFormControlChange("id", e.target.value)}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>UPC number</InputLabel>
                  <Select
                    value={state.upc}
                    onChange={e => onFormControlChange("upc", e.target.value)}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Name *</InputLabel>
                  <Select
                    value={state.name}
                    onChange={e => onFormControlChange("name", e.target.value)}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Price *</InputLabel>
                  <Select
                    value={state.price}
                    onChange={e => onFormControlChange("price", e.target.value)}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Caption</InputLabel>
                  <Select
                    value={state.caption}
                    onChange={e =>
                      onFormControlChange("caption", e.target.value)
                    }
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Description</InputLabel>
                  <Select
                    value={state.description}
                    onChange={e =>
                      onFormControlChange("description", e.target.value)
                    }
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Collection</InputLabel>
                  <Select
                    value={state.collection}
                    onChange={e =>
                      onFormControlChange("collection", e.target.value)
                    }
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Category</InputLabel>
                  <Select
                    value={state.category}
                    onChange={e =>
                      onFormControlChange("category", e.target.value)
                    }
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Sub Category</InputLabel>
                  <Select
                    value={state.sub_category}
                    onChange={e =>
                      onFormControlChange("sub_category", e.target.value)
                    }
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Designer Name</InputLabel>
                  <Select
                    value={state.designer}
                    onChange={e =>
                      onFormControlChange("designer", e.target.value)
                    }
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl variant={"outlined"} fullWidth disabled={loading}>
                  <InputLabel variant={"filled"}>Related To</InputLabel>
                  <Select
                    value={state.related_to}
                    onChange={e =>
                      onFormControlChange("related_to", e.target.value)
                    }
                  >
                    <MenuItem value={""}>None</MenuItem>
                    {itemColumns.map(column => (
                      <MenuItem key={column} value={column}>
                        {column}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/*add attributes to skus too*/}
              <Grid item xs={12}>
                <TextField
                  disabled={true}
                  label={"Attributes"}
                  fullWidth
                  value={state.attributes.join(",")}
                  placeholder={"Separated by comma"}
                  variant={"outlined"}
                  margin={"normal"}
                  onChange={e =>
                    setState({
                      ...state,
                      attributes: e.target.value.split(",")
                    })
                  }
                />
              </Grid>
              {imageSheetName.length === 0 && (
                <Grid
                  item
                  xs={4}
                  container
                  justify={"center"}
                  alignItems={"center"}
                >
                  <FormControl
                    variant={"outlined"}
                    fullWidth
                    disabled={loading}
                  >
                    <InputLabel variant={"filled"}>Image</InputLabel>
                    <Select
                      value={state.image}
                      onChange={e =>
                        onFormControlChange("image", e.target.value)
                      }
                    >
                      <MenuItem value={""}>None</MenuItem>
                      {itemColumns.map(column => (
                        <MenuItem key={column} value={column}>
                          {column}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={false}
                      onChange={e =>
                        e.target.checked
                          ? setState({
                              ...state,
                              package_dimensions: {
                                height: "",
                                weight: "",
                                length: "",
                                width: ""
                              }
                            })
                          : setState({ ...state, package_dimensions: null })
                      }
                    />
                  }
                  label={"Add package dimensions"}
                />
              </Grid>
              {state.package_dimensions && (
                <>
                  <Grid item xs={4} sm={4}>
                    <FormControl
                      variant={"outlined"}
                      fullWidth
                      disabled={loading}
                    >
                      <InputLabel variant={"filled"}>Length</InputLabel>
                      <Select
                        value={state.package_dimensions.length}
                        onChange={e =>
                          onFormControlChange(
                            "dimension.length",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value={""}>None</MenuItem>
                        {itemColumns.map(column => (
                          <MenuItem key={column} value={column}>
                            {column}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormControl
                      variant={"outlined"}
                      fullWidth
                      disabled={loading}
                    >
                      <InputLabel variant={"filled"}>Width</InputLabel>
                      <Select
                        value={state.package_dimensions.width}
                        onChange={e =>
                          onFormControlChange("dimension.width", e.target.value)
                        }
                      >
                        <MenuItem value={""}>None</MenuItem>
                        {itemColumns.map(column => (
                          <MenuItem key={column} value={column}>
                            {column}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormControl
                      variant={"outlined"}
                      fullWidth
                      disabled={loading}
                    >
                      <InputLabel variant={"filled"}>Height</InputLabel>
                      <Select
                        value={state.package_dimensions.height}
                        onChange={e =>
                          onFormControlChange(
                            "dimension.height",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value={""}>None</MenuItem>
                        {itemColumns.map(column => (
                          <MenuItem key={column} value={column}>
                            {column}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormControl
                      variant={"outlined"}
                      fullWidth
                      disabled={loading}
                    >
                      <InputLabel variant={"filled"}>Weight</InputLabel>
                      <Select
                        value={state.package_dimensions.weight}
                        onChange={e =>
                          onFormControlChange(
                            "dimension.weight",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value={""}>None</MenuItem>
                        {itemColumns.map(column => (
                          <MenuItem key={column} value={column}>
                            {column}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={false}
                      onChange={e =>
                        e.target.checked
                          ? setState({
                              ...state,
                              metadata: [{ id: uuid(), key: "", value: "" }]
                            })
                          : setState({ ...state, metadata: [] })
                      }
                    />
                  }
                  label={"Add metadata"}
                />
              </Grid>
              {state.metadata.map((metadata, index) => (
                <Grid item xs={12} container key={metadata.id}>
                  <Grid item xs={5}>
                    <TextField
                      disabled={loading}
                      label={"Key"}
                      fullWidth
                      value={metadata.key}
                      variant={"outlined"}
                      margin={"normal"}
                      onChange={e => {
                        const _state = Object.assign({}, state);
                        _state.metadata[index].key = e.target.value;
                        setState(_state);
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl
                      variant={"outlined"}
                      fullWidth
                      disabled={loading}
                    >
                      <InputLabel variant={"filled"}>Value</InputLabel>
                      <Select
                        value={metadata.value}
                        onChange={e =>
                          onFormControlChange(
                            `metadata.${index}`,
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value={""}>None</MenuItem>
                        {itemColumns.map(column => (
                          <MenuItem key={column} value={column}>
                            {column}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    container
                    justify={"center"}
                    alignItems={"center"}
                  >
                    <IconButton
                      onClick={() =>
                        setState({
                          ...state,
                          metadata: [
                            ...state.metadata,
                            { id: uuid(), key: "", value: "" }
                          ]
                        })
                      }
                    >
                      <AddCircleOutline />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  variant={"outlined"}
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Start import
                </Button>
              </Grid>
              <Snackbar
                open={snack.open}
                message={snack.message}
                autoHideDuration={5000}
                onClose={() => setSnack({ open: false, message: "" })}
              />
              {spreadSheets.items && (
                <DialogPopup
                  open={loading}
                  loading={loading}
                  progress={{
                    total: spreadSheets.items.length,
                    loaded: loaded
                  }}
                  loadingText={"Processing..."}
                />
              )}
            </Grid>
          )
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button onClick={onClose}>Close</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default withStyles(styles)(ImportSpreadSheetModal);
