import React, {useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Typography, Divider, withStyles, InputLabel, Select
} from "@material-ui/core";
import styles from "../ProductCard/styles";

const EditProductModal = ({product, open, onClose, onAddProductToCategory, classes, categories, rootClassName}) => {
  const [category, setCategory] = useState(product.category? product.category.id: '');

  const onAddToCategory = async () => {
    await onAddProductToCategory(product, category);
    onClose();
  };

  return <Dialog open={open}>
    <DialogTitle>{product.name}</DialogTitle>
    <DialogContent>
      <div className={rootClassName}>
        <div className={classes.imageWrapper}>
          <img
            alt="Product"
            className={classes.image}
            src={product.image}
          />
        </div>
        <div className={classes.details}>
          <Typography
            className={classes.title}
            variant="h4"
          >
            {product.name}
          </Typography>
          <Typography
            className={classes.description}
            variant="body1"
          >
            {product.description}
          </Typography>
        </div>
        <Divider />
        <div className={classes.stats}>
          <InputLabel id={'category_label'}>{'Category:  '}</InputLabel>
          <Select value={category} onChange={ e => setCategory(e.target.value)}>
            {categories.map(cat => <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>)}
          </Select>
          <Button className={classes.downloadsIcon} disabled={category.length === 0} onClick={onAddToCategory}>Add To Category</Button>
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
    </DialogActions>
  </Dialog>
};

export default withStyles(styles)(EditProductModal);
