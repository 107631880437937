import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Box,
  IconButton,
  Typography,
  Button,
  withStyles,
  Input
} from "@material-ui/core";
import { RefreshOutlined } from "@material-ui/icons";
import XLSX from "xlsx";

// Shared components
import { DisplayMode, SearchInput } from "components";

// Component styles
import styles from "./styles";

const ProductsToolbar = ({
  classes,
  className,
  refetch,
  navigation,
  onSearch,
  onAddClick,
  onImportSpreadSheet,
  ...props
}) => {

  const onImportXLSX = async e => {
    onImportSpreadSheet(e.target.files[0]);
    e.target.value = '';
  };

  const rootClassName = classNames(classes.root, className);

  const goToStripeDashboard = async () => {
    await navigation.push("/stripe-dashboard");
  };

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <Typography component={"div"}>
          <Box variant={"h1"}>Products</Box>
        </Typography>
        <span className={classes.spacer} />
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={onAddClick}
        >
          Add Product
        </Button>
        <IconButton onClick={refetch}>
          <RefreshOutlined />
        </IconButton>
        <Input
          color="primary"
          size="small"
          variant="outlined"
          type={"file"}
          inputProps={{
            accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            id: "fileInput"
          }}
          // onClick={goToStripeDashboard}
          className={classes.fileInput}
          onChange={onImportXLSX}
        />
        <label htmlFor={"fileInput"}>Import XLXS</label>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={goToStripeDashboard}
        >
          Export XLSX
        </Button>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={goToStripeDashboard}
        >
          Stripe Dashboard
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search product"
          onChange={onSearch}
        />
        {/*<span className={classes.spacer} />*/}
        {/*<DisplayMode mode="grid" />*/}
      </div>
    </div>
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductsToolbar);
