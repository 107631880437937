import React, { useState, useEffect } from "react";
import uuid from "uuid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  withStyles,
  Button,
  Grid,
  TextField,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
  Snackbar,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton
} from "@material-ui/core";

import { AddCircleOutline } from "@material-ui/icons";

import { useApolloClient } from "react-apollo-hooks";
import gql from "graphql-tag";

import { createProduct, updateProduct } from "../../../../graphql/mutations";

import { uploadFile, removeFile } from "../../../../services/storage";

import styles from "./styles";
import { createSku, createStripeProduct } from "../../../../services/stripe";
import {getProduct} from "../../../../graphql/queries";

const NewProductDialog = ({ open, product, onClose, classes }) => {
  const [state, setState] = useState({
    id: "",
    upc: "",
    name: "",
    caption: "",
    description: "",
    price: "",
    designer: "",
    package_dimensions: null,
    attributes: [],
    metadata: [],
    image: ""
  });

  const [imageFile, setImageFile] = useState(null);
  const client = useApolloClient();
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [disabled, setDisabled] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (product) {
      for (let key of Object.keys(product)) {
        if (!product[key] || typeof product[key] === "object") {
          delete product[key];
        }
      }

      delete product.__typename;

      console.log(product);

      setState(product);
    }
  }, [product]);

  const onSubmit = async () => {
    setDisabled(true);
    const payload = { ...state };

    try {
      if (payload.price === "" || isNaN(payload.price)) {
        throw new Error("Price is invalid!");
      } else payload.price = parseInt(payload.price);

      if (payload.name.length < 2) {
        throw new Error("Name is required!");
      }

      if (payload.package_dimensions) {
        if (!payload.package_dimensions.length || payload.package_dimensions.length === '' || isNaN(payload.package_dimensions.length)) {
          throw new Error("Length is invalid!");
        }

        if (!payload.package_dimensions.width || payload.package_dimensions.width === '' || isNaN(payload.package_dimensions.width)) {
          throw new Error("Width is invalid!");
        }

        if (!payload.package_dimensions.height || payload.package_dimensions.height === '' || isNaN(payload.package_dimensions.height)) {
          throw new Error("Height is invalid!");
        }

        if (!payload.package_dimensions.weight || payload.package_dimensions.weight === '' || isNaN(payload.package_dimensions.weight)) {
          throw new Error("Weight is invalid!");
        }
      }

      if (payload.metadata) {
        for (let data of payload.metadata) {
          if (data.key === '' || data.value === '')
            throw new Error('Metadata is invalid!');
        }
      }

      // done validations

      const _product = payload.id.length > 0? await client.query({
        query: gql(getProduct),
        variables: {id: payload.id},
        fetchPolicy: 'network-only'
      }): {data: {}};

      if (_product.data.getProduct && _product.data.getProduct.id) {
        throw new Error('Item Number exists');
      }

      if (imageFile) {
        if (payload.image && payload.image.length > 1) {
          await removeFile(state.image);
        }

        const res = await uploadFile(imageFile);
        console.log("uploaded file: ", res);
        payload.image = res.key;
      } else if (state.image && state.image.length > 1) {
        payload.image = state.image;
      }


      if (product) {
        // TODO: implement this part
       return _product;
      }
      const metadata = state.metadata.length > 0?  {}: '';

      for (let data of state.metadata) {
        metadata[data.key] = data.value;
      };

      let data = {
        id: payload.id,
        name: payload.name,
        caption: payload.caption,
        description: payload.description,
        attributes: payload.attributes.map(attribute => attribute.trim()),
        images:
          payload.image && payload.image.length > 0
            ? [`https://hdtv-prod.s3.amazonaws.com/public/${state.image}`]
            : null,
        metadata,
        package_dimensions: payload.package_dimensions
      };

      for (let key of Object.keys(data)){
        if (data[key]=== '' || (Array.isArray(data[key]) && data[key].length === 0)) {
          delete data[key];
        }
      }

      const stripe_prod = await createStripeProduct(data);
      console.log("created stripe product: ", stripe_prod);

      data = {
        ...data,
        upc: payload.upc,
        price: payload.price,
        image: payload.image,
        product_id: stripe_prod.id,
        meta: data.metadata ? JSON.stringify(data.metadata) : "",
        active: true,
        box_size: data.package_dimensions
          ? JSON.stringify(data.package_dimensions)
          : "",
        designer: payload.designer
      };
      delete data.images;
      delete data.metadata;
      delete data.product;
      delete data.package_dimensions;
      for (let key of Object.keys(data)){
        if (data[key]=== '' || (Array.isArray(data[key]) && data[key].length === 0)) {
          delete data[key];
        }
      }

      let res = await client.mutate({
        mutation: gql(createProduct),
        variables: { input: data }
      });
      console.log("created product: ", res);

      data.product = stripe_prod.id;
      data.image = payload.image.length > 0 ? `https://hdtv-prod.s3.amazonaws.com/public/${payload.image}` : undefined;
      delete data.product_id;
      delete data.attributes;
      delete data.active;

      console.log('sku data: ', data);

      res = await createSku(data);

      console.log("created sku", res);

      setSnackbar({ open: true, message: "Saved" });
      setState({ id: "",
        upc: "",
        name: "",
        caption: "",
        description: "",
        price: "",
        designer: "",
        package_dimensions: null,
        attributes: [],
        metadata: [],
        image: "" });
      setImageFile(null);
      onClose();
    } catch (e) {
      console.log(e);
      setSnackbar({ open: true, message: e.message });
    }
    setDisabled(false);
  };
  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>
          <Typography component={"div"}>
            <Box variant={"h1"}>{product ? "Edit" : "New"} Product</Box>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <TextField
                disabled={disabled}
                label={"Item Number"}
                fullWidth
                value={state.id}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    id: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                disabled={disabled}
                label={"UPC Number"}
                fullWidth
                value={state.upc}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    upc: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={disabled}
                label={"Name"}
                fullWidth
                value={state.name}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    name: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={disabled}
                label={"Price"}
                fullWidth
                value={state.price}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    price: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                disabled={disabled}
                label={"Caption"}
                fullWidth
                value={state.caption}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    caption: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                disabled={disabled}
                label={"Description"}
                fullWidth
                value={state.description}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    description: e.target.value
                  })
                }
              />
            </Grid>
            {/*add attributes to skus too*/}
            <Grid item xs={12} sm={12}>
              <TextField
                disabled={true}
                label={"Attributes"}
                fullWidth
                value={state.attributes.join(",")}
                placeholder={"Separated by comma"}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    attributes: e.target.value.split(",")
                  })
                }
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                disabled={disabled}
                label={"Designer"}
                fullWidth
                value={state.designer}
                variant={"outlined"}
                margin={"normal"}
                onChange={e =>
                  setState({
                    ...state,
                    designer: e.target.value
                  })
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              container
              justify={"center"}
              alignItems={"center"}
            >
              <input
                disabled={disabled}
                type={"file"}
                accept={"image/*"}
                onChange={e => {
                  setImageFile(e.target.files[0]);
                }}
              />
            </Grid>
            {state.image && state.image.length > 1 && (
              <Grid item xs={12}>
                <img src={state.image} alt={""} />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={false}
                    onChange={e =>
                      e.target.checked
                        ? setState({
                            ...state,
                            package_dimensions: {
                              height: "",
                              weight: "",
                              length: "",
                              width: ""
                            }
                          })
                        : setState({ ...state, package_dimensions: null })
                    }
                  />
                }
                label={"Add package dimensions"}
              />
            </Grid>
            {state.package_dimensions && (
              <>
                <Grid item xs={4} sm={4}>
                  <TextField
                    disabled={disabled}
                    label={"Length"}
                    fullWidth
                    value={state.package_dimensions.length}
                    variant={"outlined"}
                    margin={"normal"}
                    onChange={e =>
                      setState({
                        ...state,
                        package_dimensions: {
                          ...state.package_dimensions,
                          length: e.target.value
                        }
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    disabled={disabled}
                    label={"Width"}
                    fullWidth
                    value={state.package_dimensions.width}
                    variant={"outlined"}
                    margin={"normal"}
                    onChange={e =>
                      setState({
                        ...state,
                        package_dimensions: {
                          ...state.package_dimensions,
                          width: e.target.value
                        }
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    disabled={disabled}
                    label={"Height"}
                    fullWidth
                    value={state.package_dimensions.height}
                    variant={"outlined"}
                    margin={"normal"}
                    onChange={e =>
                      setState({
                        ...state,
                        package_dimensions: {
                          ...state.package_dimensions,
                          height: e.target.value
                        }
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    disabled={disabled}
                    label={"Weight"}
                    fullWidth
                    value={state.package_dimensions.weight}
                    variant={"outlined"}
                    margin={"normal"}
                    onChange={e =>
                      setState({
                        ...state,
                        package_dimensions: {
                          ...state.package_dimensions,
                          weight: e.target.value
                        }
                      })
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={false}
                    onChange={e =>
                      e.target.checked
                        ? setState({
                            ...state,
                            metadata: [{ id: uuid(), key: "", value: "" }]
                          })
                        : setState({ ...state, metadata: [] })
                    }
                  />
                }
                label={"Add metadata"}
              />
            </Grid>
            {state.metadata.map((metadata, index) => (
              <Grid item xs={12} container key={metadata.id}>
                <Grid item xs={5}>
                  <TextField
                    disabled={disabled}
                    label={"Key"}
                    fullWidth
                    value={metadata.key}
                    variant={"outlined"}
                    margin={"normal"}
                    onChange={e => {
                      const _state = Object.assign({}, state);
                      _state.metadata[index].key = e.target.value;
                      setState(_state);
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    disabled={disabled}
                    label={"Value"}
                    fullWidth
                    value={metadata.value}
                    variant={"outlined"}
                    margin={"normal"}
                    onChange={e => {
                      const _state = Object.assign({}, state);
                      _state.metadata[index].value = e.target.value;
                      setState(_state);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  justify={"center"}
                  alignItems={"center"}
                >
                  <IconButton
                    onClick={() =>
                      setState({
                        ...state,
                        metadata: [
                          ...state.metadata,
                          { id: uuid(), key: "", value: "" }
                        ]
                      })
                    }
                  >
                    <AddCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={() => onSubmit()}>
            Submit
          </Button>
          <Button disabled={disabled} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ open: false, message: "" })}
      />
    </>
  );
};

export default withStyles(styles)(NewProductDialog);
