export default theme => ({
    root: {
      padding: theme.spacing(0)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    formGroup: {
      display: 'flex'
    },
    formControl: {
      width: '40%'
    },
    progressWrapper: { position: 'relative', zIndex:9999, marginTop: -4 },
    pagination: {
      marginTop: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  });
  