import {Storage} from "aws-amplify";
import uuid from "uuid";

const removeFile = (file, video=false) => {
  return Storage.remove(file, {
    bucket: video? 'hdtv-videos-2osorqelt13t': 'hdtv-prod',
    level: 'public'
  }).then((e) => console.log('removed: ', file, e))
    .catch(err => console.log(err))
};

const uploadFile = async (file, progressCallback, video=false) => {
  console.log('uploading ', file);
  const ext = file.name.split('.').pop();
  const key = uuid() + '.' + ext;
  return await Storage.put('images/' + key, file, {
    bucket: video? 'hdtv-videos-2osorqelt13t': 'hdtv-prod',
    level: 'public',
    progressCallback
  });
};

export {removeFile, uploadFile}
